import thankyouCheckMark from 'assets/animations/thankyou-check-mark.json'
import { useLottie } from 'lottie-react'

export type ThankingCardProps = {
  title: string
  description: string
  [x: string]: any
}

export const ThankingCard = ({
  title,
  description,
  ...restProps
}: ThankingCardProps) => {
  const AnimatedView = () => {
    const options = {
      animationData: thankyouCheckMark,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  return (
    <>
      <div className='w-full bg-white rounded-xl' {...restProps}>
        <div className='mx-auto pb-[49px]'>
          <div className='flex justify-center items-center md:flex flex-col'>
            <div className='w-[228px] md:flex mt-6 mx-[49px]'>
              <AnimatedView />
            </div>

            <div className='md:flex justify-center font-Medium text-heading-5 text-Gray-800 mx-[11px] text-center'>
              {title}
            </div>

            <div className='md:flex justify-center font-Regular text-small text-Gray-600 mt-1 mx-[11px] text-center'>
              {description}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
