/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isLogOutModalOpen: false,
  userDetails: {
    email: '',
    emailToken: '',
    firstName: '',
    id: '',
    identityGuid: '',
    isActive: false,
    lastName: '',
    passwordResetRequestedOn: null,
    phoneNumber: null,
    roleId: '',
    roleName: '',
    updatedOn: '',
    userName: '',
    userPermissions: [],
    headerBarDetails: {},
    isHeaderBarDetailsUpdated: false,
  },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      ;(state.isLoading = false),
        (state.userDetails = {}),
        (state.isHeaderBarDetailsUpdated = false)
    },

    setIsLoadingState(state, { payload }) {
      state.isLoading = payload
    },

    setUserDetailsState(state, { payload }) {
      state.userDetails = payload
    },

    setUserPermissionsState(state, { payload }) {
      state.userDetails.userPermissions = payload
    },

    setLogoutModalShowState(state, action) {
      state.isLogOutModalOpen = true
    },

    setLogoutModalHideState(state, action) {
      state.isLogOutModalOpen = false
    },

    setHeaderBarDetailsState(state, { payload }) {
      state.headerBarDetails = payload
    },

    setIsHeaderBarDetailsUpdatedState(state, action) {
      state.isHeaderBarDetailsUpdated = !state.isHeaderBarDetailsUpdated
    },
  },
})

export const {
  resetToInitialState,
  setIsLoadingState,
  setUserDetailsState,
  setLogoutModalShowState,
  setLogoutModalHideState,
  setUserPermissionsState,
  setHeaderBarDetailsState,
  setIsHeaderBarDetailsUpdatedState,
} = userSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setIsLoading =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState(payload))
  }

export const setUserDetails =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setUserDetailsState(value))
  }

export const setUserPermissions =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setUserPermissionsState(value))
  }

export const setLogoutModalShow =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setLogoutModalShowState(value))
  }

export const setLogoutModalHide =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setLogoutModalHideState(value))
  }

export const setHeaderBarDetails =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setHeaderBarDetailsState(value))
  }

export const setIsHeaderBarDetailsUpdated =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsHeaderBarDetailsUpdatedState(value))
  }

export default userSlice.reducer
