import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

export interface VerifyEmailResponse {
  message: string
  messageTitle: string | null
}

interface useVerifyEmailProps {
  candidateId: string
  refereeId?: string | null
}

export const useVerifyEmail = () => {
  const mutate = useMutation(
    ({ candidateId, refereeId }: useVerifyEmailProps) => {
      return get(
        refereeId
          ? `facade/Candidate/VerifyEmail?CandidateId=${candidateId}&RefereeId=${refereeId}`
          : `facade/Candidate/VerifyEmail?CandidateId=${candidateId}`,
        {},
      )
    },
  )

  return mutate
}

export default useVerifyEmail
