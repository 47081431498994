import CN from 'classnames'

export const LinearStepper = ({ steps, currentStep }) => {
  return (
    <div className='flex items-center justify-center space-x-3'>
      {steps.map((step, index) => (
        <>
          <div className='flex flex-col md:flex-row items-center space-y-3 md:space-x-3 md:space-y-0'>
            <div
              className={CN(
                'w-8 h-8 flex items-center justify-center rounded-full font-Medium',
                {
                  'border-2 border-Cobalt-600 bg-white text-Cobalt-600':
                    index + 1 === currentStep,
                  'border-0 shadow-md bg-white  text-Cobalt-600':
                    index + 1 > currentStep,
                  'border-2 border-Cobalt-600 bg-Cobalt-600  text-white':
                    index + 1 < currentStep,
                },
              )}>
              {index + 1 < currentStep ? (
                <i className='ri-check-line text-white text-small' />
              ) : (
                <span>{step.number}</span>
              )}
            </div>
            <span
              className={CN('ml-2 font-Medium text-center', {
                'text-Gray-500': index + 1 > currentStep,
                'text-Cobalt-600':
                  index + 1 < currentStep || index + 1 === currentStep,
              })}>
              {step.label}
            </span>
          </div>
          {index + 1 < steps.length && (
            <div className='h-0.5 w-10 bg-Cobalt-600 mt-0.5' />
          )}
        </>
      ))}
    </div>
  )
}
