import React from 'react'

import { setEmbededVideoLink } from '../setEmbededVideoLink'

type IFrameVideoProps = {
  link: string
}

const IFrameVideo = ({ link }: IFrameVideoProps) => {
  return (
    <div className='w-1/2 pointer-events-auto'>
      <iframe
        // width={500}
        height={235}
        className='w-11/12'
        src={setEmbededVideoLink(link)}
        title='Video Preview'
        frameBorder={0}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </div>
  )
}

export default IFrameVideo
