/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  clickValue: Math.random(),
  backFunction: null,
  IsCareerInterest: false,
  IsStaffRequest: false,
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      ;(state.isLoading = false),
        (state.clickValue = Math.random()),
        (state.backFunction = null)
    },

    setIsLoadingState(state, { payload }) {
      state.isLoading = payload
    },

    setBackFunctionState(state, { payload }) {
      state.backFunction = payload
    },

    setLayoutClickValueState(state, { payload }) {
      state.clickValue = Math.random()
    },

    setIsCareerInterestState(state, { payload }) {
      state.IsCareerInterest = payload
    },

    setIsStaffRequestState(state, { payload }) {
      state.IsStaffRequest = payload
    },
  },
})

export const {
  resetToInitialState,
  setIsLoadingState,
  setLayoutClickValueState,
  setBackFunctionState,
  setIsCareerInterestState,
  setIsStaffRequestState,
} = layoutSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setIsLoading =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState(payload))
  }

export const setBackFunction =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setBackFunctionState(payload))
  }

export const setLayoutClickValue =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setLayoutClickValueState(value))
  }

export const setIsCareerInterest =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsCareerInterestState(payload))
  }

export const setIsStaffRequest =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsStaffRequestState(payload))
  }

export default layoutSlice.reducer
