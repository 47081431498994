import { FC } from 'react'

export interface FooterProps {
  [x: string]: any
}

export const Footer: FC<FooterProps> = () => {
  return (
    <div className='mt-5 flex flex-col items-center text-[10px] sm:text-[12px]'>
      <span className='whitespace-nowrap text-Gray-800'>
        {`Copyright © ${new Date().getFullYear()} RefHub. All rights reserved.`}
      </span>
      <a
        target='_blank'
        href='https://www.refhub.com.au/privacy-policy'
        className='whitespace-nowrap text-Cobalt-600 font-Medium mt-3'>
        Privacy Policy
      </a>
    </div>
  )
}

Footer.defaultProps = {}

export default Footer
