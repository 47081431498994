import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface TalentRequestFormInterface {
  talentRequestFormDetails?: any
}

export const useCreateTalentRequest = () => {
  const mutate = useMutation(
    ({ talentRequestFormDetails }: TalentRequestFormInterface) => {
      return postAPI(`/RequestTalent`, {
        firstName: talentRequestFormDetails?.firstName,
        lastName: talentRequestFormDetails?.lastName,
        phoneNumber: talentRequestFormDetails?.phoneNumber,
        email: talentRequestFormDetails?.email,
        companyName: talentRequestFormDetails?.companyName,
        industry: talentRequestFormDetails?.industry,
        state: talentRequestFormDetails?.state,
        comments: talentRequestFormDetails?.comments,
      })
    },
  )
  return mutate
}

export default useCreateTalentRequest
