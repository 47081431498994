/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useGetCandidateById } from 'framework/api/methods'
import { useDeclineCandidateAssessment } from 'framework/api/methods/assessment/declineCandidateAssessment'
import { useImageUpload } from 'framework/api/methods/assessment/imageUpload'
import { useGetInitialAssessmentDetailsById } from 'framework/api/methods/assessment/useGetInitialAssessmentDetailsById'
import { useWebcamPermission } from 'hooks'
import html2canvas from 'html2canvas'

import { MainLayout } from 'components/layouts/MainLayout'

import Questions from './Questions/Questions'
import InitialPage from './InitialPage'

type AssessmentDetailsProps = {
  imageSource?: string | undefined
  [x: string]: any
}

export const AssessmentDetails = ({
  className,
  ...restProps
}: AssessmentDetailsProps) => {
  const [searchParams] = useSearchParams()
  const MAX_SNAPS = 11

  const { hasWebcamPermission, stopWebcam } = useWebcamPermission()
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)
  const [isWebcamAccess, setIsWebcamAccess] = useState(false)
  const assessmentResponseId = searchParams.get('assessmentResponseId')

  const [isContinueButtonClicked, setIsContinueButtonClicked] =
    useState<boolean>(false)
  const [isTermsAndCondition, setIsTermsAndCondition] = useState(false)
  const { candidateProfile, agencyData } = useSelector(
    (state: any) => state.candidate,
  )
  const {
    refetch: refetchInitialAssessment,
    data: initialAssessment,
    isLoading: isLoadingInitialAssessment,
  } = useGetInitialAssessmentDetailsById(assessmentResponseId)

  //   const EXAM_DURATION =
  //     parseFloat(initialAssessment?.duration || '0') * 60 * 1000
  //   const INTERVAL = EXAM_DURATION / MAX_SNAPS
  const { pathname: locationPath } = useLocation()

  const candidateId = locationPath.split('/')[2]
  const assessmentId = locationPath.split('/')[4]
  const token = sessionStorage.getItem('token')
  //   const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isOpenDeclineCheck, setIsOpenDeclineCheck] = useState(false)
  const [snaps, setSnaps] = useState<string[]>([])
  const [screenshots, setScreenshots] = useState<string[]>([])
  //   const [isCapturing, setIsCapturing] = useState<boolean>(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  //   const {
  //     data: dynamicForm,
  //     isLoading: isLoadingDynamicForm,
  //     refetch: dynamicAssessmentRefetch,
  //   } = useGetDynamicAssessmentForm(assessmentId ?? '')

  //   const startCapture = () => {
  //     setIsCapturing(true)
  //     navigator.mediaDevices
  //       .getUserMedia({ video: true })
  //       .then((stream) => {
  //         if (videoRef.current) {
  //           videoRef.current.srcObject = stream
  //           videoRef.current.play()
  //         }
  //       })
  //       .catch((err) => console.error('Error accessing webcam: ', err))
  //   }

  const webCamAccess = async (isMandatory: boolean) => {
    // if (hasWebcamPermission) {
    //   setIsContinueButtonClicked(true)
    // } else {
    try {
      await navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream

            const playPromise = videoRef.current.play()
            if (playPromise !== undefined && playPromise !== null) {
              playPromise
                .then(() => {
                  setIsContinueButtonClicked(true)
                  setIsWebcamAccess(true)
                })
                .catch((error) => {
                  console.log(error.message)
                })
            } else {
              setIsContinueButtonClicked(!isMandatory)
              setIsWebcamAccess(false)
              isMandatory &&
                Toast({
                  alertHeader:
                    'Webcam is not activates. Please activate your webcam and try again',
                  status: 'Warning',
                })
              console.log('webcam is not activated')
            }
          }
        })
        .catch((err) => {
          setIsContinueButtonClicked(!isMandatory)
          setIsWebcamAccess(false)
          isMandatory &&
            Toast({
              alertHeader:
                'Webcam is blocked. Please reset your camera permission and try again',
              status: 'Error',
            })
          console.error('Error accessing webcam: ', err)
        })
    } catch (err) {
      setIsContinueButtonClicked(!isMandatory)
      setIsWebcamAccess(false)
      isMandatory &&
        Toast({
          alertHeader:
            'Webcam is blocked. Please reset your camera permission and try again',
          status: 'Error',
        })
      console.error('Error accessing the webcam: ', err)
    }
    // }
  }

  const handleContinue = async () => {
    // startWebcam()
    if (initialAssessment?.isWebCamMandatory) {
      //   if (hasWebcamPermission && isWebcamOn) {
      //     setIsContinueButtonClicked(true)
      //   } else {
      //     await webCamAccess(true)
      //   }
      await webCamAccess(true)
    } else {
      //   if (hasWebcamPermission && isWebcamOn) {
      //     setIsContinueButtonClicked(true)
      //   } else {
      //     await webCamAccess(false)
      //   }
      await webCamAccess(false)
      //   setIsContinueButtonClicked(true)
    }
  }

  const captureImage = () => {
    if (
      canvasRef.current &&
      videoRef.current &&
      snaps.length < MAX_SNAPS &&
      isWebcamAccess
    ) {
      const context = canvasRef.current.getContext('2d')
      if (context) {
        context.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height,
        )
        // const image = canvasRef.current.toDataURL('image/png')
        // setSnaps((prevImages) => [...prevImages, image])
        // sendImageToBackend(image)
        canvasRef.current.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], `screenshot_${Date.now()}.png`, {
              type: 'image/png',
            })
            setSnaps((prevScreenshots: any) => [...prevScreenshots, file])
            sendImageToBackend(file)
          }
        }, 'image/png')
        // captureScreenshot() // Take a screenshot after capturing the image
      }
    }
    //  else if (!isWebcamAccess) {
    //   captureScreenshot()
    // }
  }

  // screenshot feature hide
  // const captureScreenshot = () => {
  //   if (screenshots.length < 10) {
  //     const element = document.getElementById('root')
  //     if (!element) {
  //       return
  //     }
  //     html2canvas(element)
  //       .then((canvas) => {
  //         canvas.toBlob((blob) => {
  //           if (blob) {
  //             const file = new File([blob], `screenshot_${Date.now()}.png`, {
  //               type: 'image/png',
  //             })
  //             setScreenshots((prevScreenshots: any) => [
  //               ...prevScreenshots,
  //               file,
  //             ])
  //             sendImageToBackend(file)
  //           }
  //         }, 'image/png')
  //       })
  //       .catch((err) => console.error('Error capturing screenshot: ', err))
  //   }
  // }

  const { mutate: uploadImage /*isLoading: uploadImageLoading*/ } =
    useImageUpload()

  const sendImageToBackend = (image: File) => {
    uploadImage(
      {
        AId: assessmentId,
        CId: candidateId,
        AssessmentScreenCaptureImage: image,
      },
      {
        // onSettled: async ({ data }: any) => {},
        onError: () => {
          Toast({
            alertHeader: 'Something went wrong',
            alertBody: '',
            status: 'Error',
          })
        },
      },
    )
  }

  const currentStep = 1

  const steps = [
    { number: 1, label: 'About Assessment' },
    { number: 2, label: 'Assessment' },
  ]

  const { isLoading: isLoadingCandidateInfo } = useGetCandidateById(
    sessionStorage.getItem('candidateId'),
  )

  useEffect(() => {
    if (token && token.length > 0) {
      refetchInitialAssessment()
    }
  }, [assessmentResponseId, token, agencyData, candidateProfile])

  const AssessmentDetailsClasses = CN(
    `candidate-details mt-5 md:mt-0`,
    className,
  )

  useEffect(() => {
    if (hasWebcamPermission) {
      Toast({
        alertHeader:
          'Webcam permission has been granted for our application. Your webcam will be activated once the assessment starts',
        status: 'Warning',
      })
    }
  }, [hasWebcamPermission])

  const {
    mutateAsync: declineAssessment,
    isLoading: isLoadingDeclineAssessment,
  } = useDeclineCandidateAssessment()

  const handleAssessmentDecline = () => {
    if (candidateId && assessmentId && assessmentResponseId) {
      declineAssessment({
        candidateId: candidateId,
        assessmentId: assessmentId,
        assessmentResponseId: assessmentResponseId,
      })
        .then(() => {
          Toast({
            alertHeader: 'Assessment is declined successfully',
            status: 'Success',
          })
          stopWebcam()
          navigate(
            `/candidate/${candidateId}/assessment/${assessmentId}/thankyou`,
            {
              state: {
                title: 'Thank you!',
                description: 'We will get back to you shortly',
                hasBottomCards: true,
              },
            },
          )
        })
        .finally(() => {
          setIsOpenDeclineCheck(false)
        })
    }
  }

  return (
    <div className='overflow-hidden'>
      <video ref={videoRef} style={{ display: 'none' }} />
      <canvas
        ref={canvasRef}
        style={{ display: 'none' }}
        width='640'
        height='480'
      />
      {isContinueButtonClicked ? (
        <Questions
          initialAssessment={initialAssessment}
          maxSnaps={MAX_SNAPS}
          captureImage={captureImage}
          handleAssessmentDecline={handleAssessmentDecline}
          isLoadingDeclineAssessment={isLoadingDeclineAssessment}
          stopWebcam={stopWebcam}
        />
      ) : (
        <MainLayout hasLeftMainContent={true}>
          <InitialPage
            AssessmentDetailsClasses={AssessmentDetailsClasses}
            currentStep={currentStep}
            steps={steps}
            restProps={restProps}
            isLoadingCandidateInfo={isLoadingCandidateInfo}
            isLoadingInitialAssessment={isLoadingInitialAssessment}
            initialAssessment={initialAssessment}
            setShowDisclaimerModal={setShowDisclaimerModal}
            showDisclaimerModal={showDisclaimerModal}
            isOpenDeclineCheck={isOpenDeclineCheck}
            handleAssessmentDecline={handleAssessmentDecline}
            setIsOpenDeclineCheck={setIsOpenDeclineCheck}
            isLoadingDeclineAssessment={isLoadingDeclineAssessment}
            setIsTermsAndCondition={setIsTermsAndCondition}
            isTermsAndCondition={isTermsAndCondition}
            handleContinue={handleContinue}
          />
        </MainLayout>
      )}
    </div>
  )
}
