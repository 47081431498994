import { useCallback, useEffect, useState } from 'react'

export const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [showFullscreenWarningModal, setShowFullscreenWarningModal] =
    useState(false)

  const enterFullscreen = useCallback(() => {
    const element = document.documentElement
    if (element?.requestFullscreen) {
      element.requestFullscreen().catch((error) => {
        console.error('Failed to enter fullscreen mode:', error)
      })
    }
  }, [])

  const exitFullscreen = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((error) => {
        console.error('Failed to exit fullscreen mode:', error)
      })
      setIsFullscreen(false)
    }
  }, [])

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setShowFullscreenWarningModal(true)
        setIsFullscreen(false)
      } else {
        setShowFullscreenWarningModal(false)
        setIsFullscreen(true)
      }
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)
    document.addEventListener('mozfullscreenchange', handleFullscreenChange)
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
    document.addEventListener('msfullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullscreenChange,
      )
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange,
      )
      document.removeEventListener('msfullscreenchange', handleFullscreenChange)
    }
  }, [])

  useEffect(() => {
    enterFullscreen()
    return () => exitFullscreen()
  }, [enterFullscreen, exitFullscreen])

  return {
    isFullscreen,
    showFullscreenWarningModal,
    enterFullscreen,
    exitFullscreen,
  }
}
