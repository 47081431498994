import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import ReachedTimeModal from './ReachedTimeModal'

type TimerProps = {
  duration: number
  allowedImages: number
  onCapture: () => void
  setFormattedTime: React.Dispatch<React.SetStateAction<string>>
  addTemplatedFormCheck: (jsonData: string) => void
  formData: any
  isSavingAssessment: boolean
  handleAssessmentDecline: () => void
  isLoadingDeclineAssessment: boolean
}

export type TimerHandle = {
  handleStop: () => void
}

const Timer = forwardRef<TimerHandle, TimerProps>(
  (
    {
      duration,
      onCapture,
      allowedImages,
      setFormattedTime,
      addTemplatedFormCheck,
      formData,
      isSavingAssessment,
      handleAssessmentDecline,
      isLoadingDeclineAssessment,
    },
    ref,
  ) => {
    const [time, setTime] = useState<number>(duration)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [continueFromZero, setContinueFromZero] = useState<boolean>(false)
    const intervalRef = useRef<NodeJS.Timeout | null>(null)
    const captureIntervalRef = useRef<NodeJS.Timeout | null>(null)

    useImperativeHandle(ref, () => ({
      handleStop,
    }))

    useEffect(() => {
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime - 1)
      }, 1000)

      return () => {
        if (intervalRef.current) clearInterval(intervalRef.current)
      }
    }, [])

    useEffect(() => {
      if (time <= 0 && !continueFromZero) {
        setShowModal(true)
        setTime(0)
      }
      //   else if (continueFromZero && time <= -duration) {
      //     console.log('true')
      //     onCapture()
      //   }
    }, [time, continueFromZero, duration, onCapture])

    useEffect(() => {
      //   const interval = (duration / allowedImages) * 1000
      //   let count = 0
      const adjustedDuration = duration - 10

      const interval = (adjustedDuration * 1000) / allowedImages

      let count = 0

      captureIntervalRef.current = setInterval(() => {
        if (count < allowedImages) {
          onCapture()
          count++
        } else {
          clearInterval(captureIntervalRef.current!)
        }
      }, interval)

      return () => {
        if (captureIntervalRef.current)
          clearInterval(captureIntervalRef.current)
      }
    }, [duration, onCapture, allowedImages])

    const handleContinue = () => {
      setShowModal(false)
      setContinueFromZero(true)
    }

    const handleCancel = () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
      setTime(0)
      //   addTemplatedFormCheck(JSON.stringify(formData))
      handleAssessmentDecline()
      !isLoadingDeclineAssessment && setShowModal(false)
    }
    const handleStop = () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
      setTime(time)
    }

    const formatTime = (seconds: number): string => {
      const absSeconds = Math.abs(seconds)
      const minutes = Math.floor(absSeconds / 60)
      const remainingSeconds = absSeconds % 60
      const formattedMinutes = String(minutes).padStart(2, '0')
      const formattedSeconds = String(remainingSeconds).padStart(2, '0')
      const formattedTime = `${
        seconds < 0 ? '-' : ''
      }${formattedMinutes}:${formattedSeconds}`
      setFormattedTime(formattedTime)
      return formattedTime
    }

    return (
      <>
        <div className='w-1/5 my-5 bg-white rounded-lg p-4 border-2 border-gray-300 border-dashed'>
          <div className='flex flex-col justify-between items-center'>
            <span className='text-sm font-medium text-gray-700'>
              Time Remaining
            </span>
            <span className='bg-[#FEF3C7] text-[#92400E] px-4 py-1 rounded-full'>
              {formatTime(time)}
            </span>
          </div>
        </div>
        {showModal && (
          <ReachedTimeModal
            showModal={showModal}
            primaryAction={handleContinue}
            secondaryAction={handleCancel}
            primaryBtnName='Continue Assessment'
            secondaryBtnName='Decline to Complete'
            closeButtonAction={handleContinue}
            isSavingAssessment={isSavingAssessment}
            isLoadingDeclineAssessment={isLoadingDeclineAssessment}
          />
        )}
      </>
    )
  },
)

export default Timer
