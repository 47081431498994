import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export type DynamicAssessmentFormType = {
  messageTitle: string
  message: string
  jsonSchema: any
  jsonUiSchema: string
  templateName?: string
  primaryUrl?: string
  secondaryUrl?: string
}

export const useGetDynamicAssessmentForm = (templateId: string) => {
  return useQuery(
    ['GET_DYNAMIC_ASSESSMENT_FORM', templateId],
    async () => {
      if (templateId && templateId !== '') {
        const res = await get(
          `assessment/${templateId}/isExam/${true}/GetDynamicAssessmentForm`,
        )
        return res.data as DynamicAssessmentFormType
      }
    },
    {
      enabled: templateId != '',
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching dynamic form',
          status: 'Error',
        })
      },
    },
  )
}
