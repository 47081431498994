import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Button, Modal, Toast } from '@labourhub/labour-hub-ds'
import JSONForm from '@rjsf/core'
import { useGetMappedVariables } from 'api'
import CN from 'classnames'
import {
  useDeclineCandidateSurvey,
  useGetDynamicForm,
  useSubmitCandidateSurvey,
} from 'framework/api/methods'

import { PageLoader, TitleHeader } from 'components/atoms'
import { FormBuilder } from 'components/molecules'

type CandidateSurveyProps = {
  [x: string]: any
}

export const CandidateSurvey = ({
  className,
  ...restProps
}: CandidateSurveyProps) => {
  const { cid: candidateId, surveyId } = useParams()

  const [searchParams] = useSearchParams()
  const surveyResponseId = searchParams.get('surveyResponseId')

  const [isOpenDeclineSurvey, setIsOpenDeclineSurvey] = useState(false)

  const [dynamicFieldMapping, setDynamicFieldMapping] = useState<Record<
    string,
    string
  > | null>(null)

  useGetMappedVariables({ candidateId: candidateId ?? '' }, (data) => {
    setDynamicFieldMapping(data)
  })

  const CandidateSurveyClasses = CN(`candidateSurvey`, className)
  const componentRef = useRef<HTMLDivElement>(null)

  const dynamicFormRef = useRef<JSONForm<any, any> | null>(null)
  const navigate = useNavigate()

  // Adding Candidate Survey
  const { mutateAsync: submitCandidateSurvey, isLoading: isSavingSurvey } =
    useSubmitCandidateSurvey()

  const addTemplatedFormCheck = (jsonData: string) => {
    if (candidateId && surveyId && surveyResponseId) {
      submitCandidateSurvey({
        candidateId,
        surveyId,
        jsonData,
        surveyResponseId,
      }).then(() => {
        navigate(`/candidate/${candidateId}/surveys/${surveyId}/thankyou`, {
          state: {
            title: 'Thank you!',
            description: 'We will get back to you shortly',
            hasBottomCards: true,
          },
        })
      })
    }
  }

  // Decline Candidate Survey
  const { mutateAsync: declineSurvey, isLoading: isLoadingDeclineSurvey } =
    useDeclineCandidateSurvey()

  const handleSurveyDecline = () => {
    if (candidateId && surveyId && surveyResponseId) {
      declineSurvey({
        candidateId: candidateId,
        surveyId: surveyId,
        surveyResponseId: surveyResponseId,
      })
        .then(() => {
          navigate(`/candidate/${candidateId}/surveys/${surveyId}/thankyou`, {
            state: {
              title: 'Thank you!',
              description: 'We will get back to you shortly',
              hasBottomCards: true,
            },
          })
        })
        .finally(() => {
          setIsOpenDeclineSurvey(false)
        })
    }
  }

  // Fetching dynamic data for the template
  const { data: dynamicForm, isLoading: isLoadingDynamicForm } =
    useGetDynamicForm(surveyId ?? '')

  useEffect(() => {
    const handleTextareaInput = (event: Event) => {
      const textarea = event.target as HTMLTextAreaElement
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }

    const textareas = componentRef.current
      ?.querySelector('form.jason-theme')
      ?.querySelectorAll('textarea')

    if (textareas) {
      textareas.forEach((textarea) => {
        if (textarea instanceof HTMLTextAreaElement) {
          textarea.rows = 5
          textarea.addEventListener('input', handleTextareaInput)
        }
      })
    }

    return () => {
      if (textareas) {
        textareas.forEach((textarea) => {
          if (textarea instanceof HTMLTextAreaElement) {
            textarea.removeEventListener('input', handleTextareaInput)
          }
        })
      }
    }
  }, [dynamicForm])

  const changeJsonSchema = (schema: any) => {
    const properties = { ...schema.properties }

    let tempIndex = 0

    if (dynamicFieldMapping) {
      Object.keys(properties).map((key: string) => {
        const property = properties[key]

        // map dynamic fields
        Object.entries(dynamicFieldMapping).forEach(([code, value]) => {
          if (property?.title !== '') {
            property.title = property.title.replaceAll(`@${code}`, value)
          }

          if (property?.title === '' && property?.description !== '') {
            property.description = property.description.replaceAll(
              `@${code}`,
              value,
            )
          }
        })

        // map question numbers
        if (property?.type !== 'object') {
          property.title = `${tempIndex < 9 ? '0' : ''}${tempIndex + 1}. ${
            property.title
          }`
          tempIndex++
        }
      })
    }

    return {
      ...schema,
      properties,
    }
  }

  return (
    <div className={CandidateSurveyClasses} {...restProps}>
      {isLoadingDynamicForm && <PageLoader size='xxs' />}

      {/* header section */}
      <TitleHeader
        title={dynamicForm?.templateName || 'Survey'}
        subTitle='Please take a moment to complete the survey'
      />

      {dynamicForm && (
        <div ref={componentRef} className='p-4 my-5 bg-white rounded-lg'>
          <FormBuilder
            ref={dynamicFormRef}
            uiSchema={JSON.parse(dynamicForm.jsonUiSchema)}
            schema={changeJsonSchema(JSON.parse(dynamicForm.jsonSchema))}
            onError={(data) => {
              if (data.length > 0) {
                Toast({
                  alertHeader: 'One or more field is required.',
                  status: 'Warning',
                })
              }
            }}
            onSubmit={(data) => {
              addTemplatedFormCheck(JSON.stringify(data?.formData))
            }}
          />

          <div className='flex gap-2'>
            <Button
              onClick={() => {
                dynamicFormRef.current?.submit()
              }}
              isLoading={isSavingSurvey}>
              Submit Answers
            </Button>
            <Button
              theme='white'
              textTheme='blue'
              onClick={() => setIsOpenDeclineSurvey(true)}>
              Decline Answer
            </Button>
          </div>
        </div>
      )}

      {/* Decline Referee modal */}
      <Modal
        isActive={isOpenDeclineSurvey}
        onClickPrimaryBtn={() => {
          handleSurveyDecline()
        }}
        className={'p-4'}
        onClickSecondaryBtn={() => {
          setIsOpenDeclineSurvey(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: isLoadingDeclineSurvey,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsOpenDeclineSurvey(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Close'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Are you sure you want to Decline?
          </span>
        </div>
      </Modal>
    </div>
  )
}
