import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface GetCandidateByIdResponse {
  message: string
  messageTitle: string | null
  basicCandidateProfile: CandidateProfile
}

export interface SocialLinks {
  facebook: string
  twitter: string
  linkedIn: string
}

export interface CandidateProfile {
  name: string
  firstName: string
  candidateNo: string
  email: string
  phone: string
  jobTitle: string
  refereeCount: number
  remainingRefereeCount: number
  lastSendRequest: string
  requestSendDate: string
  avatarUrl: string
  passCount: number
  pendingCount: number
  failedCount: number
  percentage: string
}

export const useGetCandidateById = (
  candidateId: string | undefined | null,
  onSuccess?: (data: GetCandidateByIdResponse) => void,
) => {
  return useQuery(
    ['GET_CANDIDATE_BY_ID', candidateId],
    async () => {
      const data = await get(`facade/Candidate/${candidateId}/GetCard`)
      return (data?.data as GetCandidateByIdResponse) || []
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching candidate profile',
          status: 'Error',
        })
      },
      onSuccess,
    },
  )
}
