/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Checkbox, Modal } from '@labourhub/labour-hub-ds'
import { GetInitialAssessmentByIdResponse } from 'framework/api/methods/assessment/useGetInitialAssessmentDetailsById'

import { LinearStepper, PageLoader, TitleHeader } from 'components/atoms'

import MainContent from './About/MainContent'

type InitialPageProps = {
  AssessmentDetailsClasses: string
  steps: {
    number: number
    label: string
  }[]
  currentStep: number
  restProps: any
  isLoadingCandidateInfo: boolean
  isLoadingInitialAssessment: boolean
  isLoadingDeclineAssessment: boolean
  initialAssessment: GetInitialAssessmentByIdResponse | undefined
  setShowDisclaimerModal: React.Dispatch<React.SetStateAction<boolean>>
  showDisclaimerModal: boolean
  setIsOpenDeclineCheck: React.Dispatch<React.SetStateAction<boolean>>
  isOpenDeclineCheck: boolean
  handleAssessmentDecline: () => void
  setIsTermsAndCondition: React.Dispatch<React.SetStateAction<boolean>>
  isTermsAndCondition: boolean
  handleContinue: () => Promise<void>
}

const InitialPage = ({
  AssessmentDetailsClasses,
  steps,
  currentStep,
  restProps,
  isLoadingCandidateInfo,
  isLoadingInitialAssessment,
  isLoadingDeclineAssessment,
  initialAssessment,
  setShowDisclaimerModal,
  showDisclaimerModal,
  setIsOpenDeclineCheck,
  isOpenDeclineCheck,
  handleAssessmentDecline,
  setIsTermsAndCondition,
  isTermsAndCondition,
  handleContinue,
}: InitialPageProps) => {
  return (
    <div>
      <div className={AssessmentDetailsClasses} {...restProps}>
        {/* header section */}
        <TitleHeader
          title='Assessment'
          subTitle={`Please complete the requested assessment`}
        />

        {/* stepper section */}
        <div className='flex p-5 mt-5 bg-white w-full justify-center items-center'>
          <LinearStepper steps={steps} currentStep={currentStep} />
        </div>

        {/* candidate detail and disclaimer section */}
        <div className='relative flex flex-col w-full h-auto p-4 mt-5 bg-Cobalt-50 rounded-lg main-container'>
          {(isLoadingCandidateInfo || isLoadingInitialAssessment) && (
            <PageLoader size='xxs' />
          )}

          <div className='hidden p-4 rounded-lg md:flex md:flex-col text-small'>
            <span className='text-heading-5 font-Medium text-Gray-800'>
              About the Assessment
            </span>
            <MainContent initialAssessment={initialAssessment} />
          </div>

          {/* mobile view disclaimer */}
          <div className='flex justify-center w-full mt-6 md:hidden'>
            <Button
              onClick={() => {
                setShowDisclaimerModal(true)
              }}
              textTheme='blue'
              theme='link'>
              View About the Assessment
            </Button>
          </div>

          {/* mobile view disclaimer modal */}
          <Modal
            headerTitle='About the Assessment'
            isActive={showDisclaimerModal}
            isFooterShow={false}
            onHeaderCloseButtonClick={() => {
              setShowDisclaimerModal(false)
            }}
            onOverlayClick={() => {
              setShowDisclaimerModal(false)
            }}>
            <div className='flex flex-col h-auto p-6'>
              <MainContent initialAssessment={initialAssessment} />
            </div>
          </Modal>

          {/* Decline Referee modal */}
          <Modal
            isActive={isOpenDeclineCheck}
            onClickPrimaryBtn={() => {
              handleAssessmentDecline()
            }}
            className={'p-4'}
            onClickSecondaryBtn={() => {
              setIsOpenDeclineCheck(false)
            }}
            primaryButtonProps={{
              style: { width: '100%', marginRight: '12px' },
              isLoading: isLoadingDeclineAssessment,
            }}
            secondaryButtonProps={{
              style: { width: '100%', color: '#1F2937' },
            }}
            onOverlayClick={() => {
              setIsOpenDeclineCheck(false)
            }}
            modalProps={{
              style: { width: '466px' },
            }}
            footerProps={{
              style: {
                justifyContent: 'space-between',
                paddingLeft: '23px',
                paddingRight: '23px',
              },
            }}
            isHeaderShow={false}
            primaryButtonTitle='Yes, I’m Sure'
            secondaryButtonTitle='Close'>
            <div className='flex flex-col items-center justify-center p-6'>
              <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
                <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
              </div>

              <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
                Are you sure you want to Decline?
              </span>
            </div>
          </Modal>
        </div>
        <div className='flex flex-col mb-0 md:mt-4 md:flex-row'>
          <Checkbox
            labelText='I agree to the Terms and conditions.'
            className='mt-2'
            labelTextClassName='font-Regular'
            checkboxClassName='mt-0.5'
            checked={isTermsAndCondition}
            onChange={(e: any) => {
              setIsTermsAndCondition(e.target.checked)
            }}
          />
        </div>
        <div className='flex flex-col md:mt-4 md:flex-row gap-y-3 md:gap-x-5'>
          <Button
            isBlock
            theme='white'
            textTheme='blue'
            onClick={() => setIsOpenDeclineCheck(true)}>
            Decline to Answer
          </Button>
          <Button
            isBlock
            isDisabled={!isTermsAndCondition}
            onClick={() => {
              handleContinue()
            }}>
            {initialAssessment?.isWebCamMandatory && 'Enable webcam & '}Continue
          </Button>
        </div>
      </div>
    </div>
  )
}

export default InitialPage
