import { useEffect, useRef, useState } from 'react'

const useOutsideMouseTracker = () => {
  const [timeOutside, setTimeOutside] = useState(0)
  const [isOutside, setIsOutside] = useState(false)
  const [timer, setTimer] = useState<any>(null)
  const startTime = useRef<number | null>(null)
  const isPageVisible = useRef(true)
  const isPageFocused = useRef(true)

  useEffect(() => {
    const handleMouseLeave = () => {
      if (!isOutside && isPageVisible.current && isPageFocused.current) {
        startTime.current = Date.now()
        setIsOutside(true)
        setTimer(
          setInterval(() => {
            setTimeOutside((prevTime) => prevTime + 1)
          }, 1000),
        )
      }
    }

    const handleMouseEnter = () => {
      if (isOutside) {
        clearInterval(timer)
        setTimer(null)
        setIsOutside(false)
        if (startTime.current) {
          const duration = Math.floor((Date.now() - startTime.current) / 1000)
          setTimeOutside((prevTime) => prevTime + duration)
        }
      }
    }

    const handleVisibilityChange = () => {
      if (document.hidden) {
        isPageVisible.current = false
        if (isOutside) {
          clearInterval(timer)
          setTimer(null)
          if (startTime.current) {
            const duration = Math.floor((Date.now() - startTime.current) / 1000)
            setTimeOutside((prevTime) => prevTime + duration)
          }
        }
      } else {
        isPageVisible.current = true
      }
    }

    const handleFocus = () => {
      isPageFocused.current = true
      if (isOutside) {
        startTime.current = Date.now()
        setTimer(
          setInterval(() => {
            setTimeOutside((prevTime) => prevTime + 1)
          }, 1000),
        )
      }
    }

    const handleBlur = () => {
      isPageFocused.current = false
      if (isOutside) {
        clearInterval(timer)
        setTimer(null)
        if (startTime.current) {
          const duration = Math.floor((Date.now() - startTime.current) / 1000)
          setTimeOutside((prevTime) => prevTime + duration)
        }
      }
    }

    document.addEventListener('mouseleave', handleMouseLeave)
    document.addEventListener('mouseenter', handleMouseEnter)
    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)

    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave)
      document.removeEventListener('mouseenter', handleMouseEnter)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [isOutside, timer])

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(
      2,
      '0',
    )}`
  }

  return { timeOutside, formatTime }
}

export default useOutsideMouseTracker
