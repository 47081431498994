/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ToastContainer } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  GetAgencyBySubDomainResponse,
  GetCandidateByIdResponse,
  useCandidateToken,
  useGetAgencyBySubDomain,
  useGetCandidateById,
} from 'framework/api/methods'
import { useVerifyEmail } from 'framework/api/methods/auth/useVerifyEmail'
import { useVerifyAssessment } from 'framework/api/methods/auth/VerifyAssessment'
import { useVerifySurvey } from 'framework/api/methods/auth/VerifySurvey'
import {
  setAgencyData,
  setCandidateProfile,
} from 'store/reducers/candidate/candidateSlice'
import { setLayoutClickValue } from 'store/reducers/layout/layoutSlice'

import { PageLoader } from 'components/atoms'
import { LeftMainContent } from 'components/common/LeftMainContent'

export interface MainLayoutProps {
  [x: string]: any
}

export const MainLayout: FC<MainLayoutProps> = ({
  hasLeftMainContent,
  children,
}: MainLayoutProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const { cid, rid } = useParams()

  const surveyResponseId = searchParams.get('surveyResponseId')
  const assessmentResponseId = searchParams.get('assessmentResponseId')

  const { mutate: candidateTokenMutate } = useCandidateToken()

  /** Process the Login submission */
  async function getCandidateToken(candidateToken: string) {
    candidateTokenMutate(
      {
        candidateToken,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          successData.token &&
            sessionStorage.setItem('token', successData.token)
          successData.refreshToken &&
            sessionStorage.setItem('refreshToken', successData.refreshToken)

          if (cid) {
            sessionStorage.setItem('candidateId', cid)
          }
          getAgencyBySubDomain()
        },
      },
    )
  }

  const getAgencyDataOnsuccess = (data: GetAgencyBySubDomainResponse) => {
    /** set agency data in store */
    dispatch(setAgencyData(data?.agencyProfile))
    data?.agencyProfile?.id &&
      sessionStorage.setItem('tenantId', data?.agencyProfile?.id)

    getCandidateById()

    if (rid) {
      verifyEmail(cid, rid)
    }

    if (surveyResponseId) {
      verifySurvey(cid, surveyResponseId)
    }
    if (assessmentResponseId) {
      verifyAssessment(cid, assessmentResponseId)
    }
  }

  const {
    refetch: getAgencyBySubDomain,
    data: agencyData,
    isLoading: loadingAgencyBySubDomain,
  } = useGetAgencyBySubDomain(
    window.location.href.split('/')[2].split('.')[0],
    getAgencyDataOnsuccess,
  )

  /** Verify Survey */
  const { mutate: verifySurveyMutate, isLoading: verifySurveyIsLoading } =
    useVerifySurvey()

  async function verifySurvey(candidateId, SurveyResponseID) {
    verifySurveyMutate(
      { candidateId, SurveyResponseID },

      {
        onError: () => {
          navigate('/already-submitted')
        },
      },
    )
  }

  /** Verify Assessment */
  const {
    mutate: verifyAssessmentMutate,
    isLoading: verifyAssessmentIsLoading,
  } = useVerifyAssessment()

  async function verifyAssessment(candidateId, AssessmentResponseID) {
    verifyAssessmentMutate(
      { candidateId, AssessmentResponseID },

      {
        onError: () => {
          navigate('/assessment-already-submitted')
        },
      },
    )
  }

  const { mutate: verifyEmailMutate, isLoading: verifyEmailIsLoading } =
    useVerifyEmail()

  /** Process the state list by country code*/
  async function verifyEmail(candidateId, refereeId) {
    verifyEmailMutate(
      { candidateId, refereeId },
      {
        onError: () => {
          navigate('/already-submitted')
        },
      },
    )
  }

  const onGetCandidateProfileSuccess = (data: GetCandidateByIdResponse) => {
    dispatch(setCandidateProfile(data.basicCandidateProfile))
  }

  const { refetch: getCandidateById } = useGetCandidateById(
    cid,
    onGetCandidateProfileSuccess,
  )

  useEffect(() => {
    sessionStorage.setItem('candidateToken', searchParams.get('token') || '')
    getCandidateToken(searchParams.get('token') || '')
  }, [])

  const renderLayoutInner = () => {
    return (
      <div className='relative flex flex-col h-full'>
        <div className='flex flex-col w-full h-full main-layout__inner'>
          {children}
        </div>
        <ToastContainer className='mt-10 w-full max-w-[420px] min-w-[350px]' />
      </div>
    )
  }

  const renderLayout = () => {
    // Render with left main content
    if (hasLeftMainContent) {
      return (
        <div className='flex flex-col md:flex-row mx-4 mt-5 md:mx-[148px] md:mt-[40px] w-full'>
          <LeftMainContent
            isLoading={loadingAgencyBySubDomain}
            agencyData={agencyData?.agencyProfile}
            className='top-[0px] left-0'
          />
          <div className='flex flex-col w-full h-full overflow-y-auto styled-scroll scrollbar-hide'>
            {renderLayoutInner()}
          </div>
        </div>
      )
    }

    // Render without sidebar
    return <div className='container'>{renderLayoutInner()}</div>
  }

  return (
    <>
      <div
        onClick={() => {
          dispatch(setLayoutClickValue())
        }}
        className={CN(
          'main-layout__container fixed flex w-full h-full bg-Gray-100',
        )}>
        {loadingAgencyBySubDomain ||
          ((verifyEmailIsLoading ||
            verifySurveyIsLoading ||
            verifyAssessmentIsLoading) && <PageLoader size='xxs' />)}
        {renderLayout()}
      </div>
    </>
  )
}

export default MainLayout
