import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

export type ImageUpload = {
  AId?: string
  CId?: string
  AssessmentScreenCaptureImage: File
}

export const useImageUpload = () => {
  const mutate = useMutation(
    ({ AId, AssessmentScreenCaptureImage, CId }: ImageUpload) => {
      const bodyFormData = new FormData()
      bodyFormData.append(
        'AssessmentScreenCaptureImage',
        AssessmentScreenCaptureImage,
      )
      return putAPIwithFormData(
        `/candidate/${CId}/assessment/${AId}/screencapture`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}
