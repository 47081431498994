import React, { FC, useEffect, useState } from 'react'
import { Button, TextArea, TextField, Toast } from '@labourhub/labour-hub-ds'
import { useCreateTalentRequest } from 'framework/api/methods'

import { PhoneInputField } from 'components/atoms'
import { isEmail, isLetters } from 'utils'

export interface TalentFormProps {
  [x: string]: any
}

export const TalentForm: FC<TalentFormProps> = ({
  setIsShowTalentForm,
  setIsShowThankingCard,
  ...restProps
}: TalentFormProps) => {
  const [talentRequestFormDetails, setTalentRequestFormDetails] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    companyName: '',
    industry: '',
    state: '',
    comments: '',
  })

  const notify = (props: any) => Toast(props)

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false,
    companyName: false,
    industry: false,
    state: false,
    comments: false,
  })

  /** front end validation for all input fields */
  const validateInputs = () => {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      companyName,
      industry,
      state,
    } = talentRequestFormDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) ? false : true,
      lastName: isLetters(lastName) ? false : true,
      email: isEmail(email) ? false : true,
      companyName: companyName ? false : true,
      industry: industry ? false : true,
      state: state ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) &&
      isLetters(lastName) &&
      phoneNumber &&
      isEmail(email) &&
      companyName &&
      industry &&
      state

    return isAllValidFields
  }

  useEffect(() => {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      companyName,
      industry,
      state,
    } = talentRequestFormDetails || {}
    ;(
      firstName &&
      lastName &&
      phoneNumber &&
      email &&
      companyName &&
      industry &&
      state
        ? false
        : true
    )
      ? setIsSubmitButtonDisabled(true)
      : setIsSubmitButtonDisabled(false)
  }, [talentRequestFormDetails])

  /** APi call for the submit talent request form */
  const { mutate: submitTalentRequestFormMutate } = useCreateTalentRequest()

  /** Process the submit talent request form */
  const submitTalentRequestForm = () => {
    submitTalentRequestFormMutate(
      {
        talentRequestFormDetails,
      },
      {
        onSuccess: () => {
          setIsShowTalentForm(false)
          setIsShowThankingCard(true)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle submit the talent request form */
  const onSubmitTalentRequestForm = () => {
    const valid = validateInputs()

    if (valid) {
      submitTalentRequestForm()
    } else {
      notify({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  return (
    <div className='pt-6 talent-request-form' {...restProps}>
      <div className='flex flex-col w-full h-auto pl-4 pr-[17px] overflow-y-auto rounded-lg bg-white pb-4'>
        <span className='text-black font-Bold text-small pt-9'>
          CONTACT INFO
        </span>
        <div className='flex flex-col md:flex-row'>
          <TextField
            className='flex-1 mt-5 md:mr-5'
            label='First Name'
            value={talentRequestFormDetails?.firstName}
            onChange={(e: any) => {
              setTalentRequestFormDetails({
                ...talentRequestFormDetails,
                firstName: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.firstName}
          />

          <TextField
            className='flex-1 mt-5 md:mr-5'
            label='Last Name'
            value={talentRequestFormDetails?.lastName}
            onChange={(e: any) => {
              setTalentRequestFormDetails({
                ...talentRequestFormDetails,
                lastName: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.lastName}
          />
        </div>

        <div className='flex flex-col mt-5 md:flex-row'>
          <TextField
            className='flex-1 md:mr-5 md:mb-0'
            label='Your Email'
            value={talentRequestFormDetails?.email}
            onChange={(e: any) => {
              setTalentRequestFormDetails({
                ...talentRequestFormDetails,
                email: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.email}
          />

          <PhoneInputField
            className='flex-1'
            label='Your Phone'
            value={talentRequestFormDetails?.phoneNumber}
            onChange={(number: any) =>
              setTalentRequestFormDetails({
                ...talentRequestFormDetails,
                phoneNumber: number,
              })
            }
          />
        </div>

        <span className='pt-10 text-black font-Bold text-small'>
          STAFF REQUIREMENT
        </span>

        <div className='flex flex-col mb-0 md:flex-row'>
          <TextField
            className='flex-1 mt-5 md:mr-5'
            label='Your Company Name'
            value={talentRequestFormDetails?.companyName}
            onChange={(e: any) => {
              setTalentRequestFormDetails({
                ...talentRequestFormDetails,
                companyName: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.companyName}
          />

          <TextField
            className='flex-1 mt-5'
            label='Your Company’s Industry'
            value={talentRequestFormDetails?.industry}
            onChange={(e: any) => {
              setTalentRequestFormDetails({
                ...talentRequestFormDetails,
                industry: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.industry}
          />
        </div>

        <div className='flex flex-col mt-5 md:flex-row'>
          <TextField
            className='flex-1 md:mr-5 md:mb-0'
            label='Your Company’s State'
            value={talentRequestFormDetails?.state}
            onChange={(e: any) => {
              setTalentRequestFormDetails({
                ...talentRequestFormDetails,
                state: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.state}
          />

          <div className='flex-1' />
        </div>
        <div className='flex flex-col mt-5 md:flex-row'>
          <TextArea
            className='flex-1'
            value={talentRequestFormDetails?.comments}
            onChange={(e: any) => {
              setTalentRequestFormDetails({
                ...talentRequestFormDetails,
                comments: e.target.value,
              })
            }}
            label='Comments'
            placeholder=''
            rows={4}
          />
        </div>

        <div className='flex flex-col mt-8 md:flex-row md:mb-2'>
          <Button
            className='flex-1 md:mr-[330px] md:mb-0 md:mt-0'
            isDisabled={isSubmitButtonDisabled}
            onClick={() => {
              onSubmitTalentRequestForm()
            }}>
            Send Talent Request
          </Button>

          <div className='flex-1' />
        </div>
      </div>
    </div>
  )
}

TalentForm.defaultProps = {}

export default TalentForm
