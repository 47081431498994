import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { postAPI } from 'framework/api/http'

type DeclineCandidateSurveyRequest = {
  candidateId: string
  surveyId: string
  surveyResponseId: string
}

export const useDeclineCandidateSurvey = () => {
  return useMutation(
    ({
      candidateId,
      surveyId,
      surveyResponseId,
    }: DeclineCandidateSurveyRequest) => {
      return postAPI(`/candidates/${candidateId}/surveys/${surveyId}/decline`, {
        surveyResponseId,
      })
    },
    {
      onError: () => {
        Toast({
          alertHeader: `Error declining survey`,
          status: 'Error',
        })
      },
    },
  )
}
