import { useState } from 'react'
import CN from 'classnames'

import { ThankingCard, TitleHeader } from 'components/atoms'
import { TalentForm } from 'components/molecules'

type TalentRequestFormProps = {
  [x: string]: any
}

export const TalentRequestForm = ({
  className,
  ...restProps
}: TalentRequestFormProps) => {
  const [isShowTalentForm, setIsShowTalentForm] = useState(true)
  const [isShowThankingCard, setIsShowThankingCard] = useState(false)

  const TalentRequestFormClasses = CN(
    'talentRequestFormClasses-form mt-5 sm:mt-0',
    className,
    {},
  )

  return (
    <div className={TalentRequestFormClasses} {...restProps}>
      {isShowTalentForm && (
        <>
          <TitleHeader
            title='Talent Request'
            subTitle='Please fill below form and send it to us. We will get back to you shortly.'
          />
          <TalentForm
            setIsShowThankingCard={setIsShowThankingCard}
            setIsShowTalentForm={setIsShowTalentForm}
          />
        </>
      )}

      {isShowThankingCard && (
        <ThankingCard
          title='Thank you!'
          description='We got your talent request and one of our team members will reach you within next 48 hours'
        />
      )}
    </div>
  )
}
