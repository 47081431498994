import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  GetCandidateByIdResponse,
  useCreateCandidateAvatar,
  useDeleteCandidateAvatar,
  useGetCandidateById,
} from 'framework/api/methods'
import { setCandidateProfile } from 'store/reducers/candidate/candidateSlice'

import { ProfileAvatar } from 'components/atoms'

import { CustomizableEditProfilePictureModal } from '../CustomizableEditProfilePictureModal'

export interface CandidateInfoCardProps {
  [x: string]: any
  candidateName: string
}

export const CandidateInfoCard: FC<CandidateInfoCardProps> = ({
  className,
  candidateName,
  candidateProfile,
  ...restProps
}: CandidateInfoCardProps) => {
  const dispatch = useDispatch()

  const [showEditProfilePictureModal, setShowEditProfilePictureModal] =
    useState(false)
  const [showDeleteProfilePictureModal, setShowDeleteProfilePictureModal] =
    useState(false)
  const [files, setFiles] = useState<any>()
  const [imageUrl, setImageUrl] = useState<any>('')

  const { showCandidateAvatarError } = useSelector(
    (state: any) => state.candidate,
  )

  /** upload profile picture api */
  const {
    mutate: UploadProfilePictureMutate,
    isLoading: uploadProfilePictureIsLoading,
  } = useCreateCandidateAvatar()

  /** Process the upload profile picture */
  async function UploadProfilePicture(Id: any, avatarObject: any) {
    UploadProfilePictureMutate(
      {
        Id,
        avatarObject,
      },
      {
        onSuccess: () => {
          setShowEditProfilePictureModal(false)
          getCandidateById()
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
          setShowEditProfilePictureModal(false)
        },
      },
    )
  }

  /** API Call for upload profile picture using react-query */
  const handleUploadProfileAvatar = (files: any) => {
    UploadProfilePicture(candidateProfile.candidateId, files)
  }

  /** API Call for delete candidate photo using react-query */
  const {
    mutate: deleteCandidateAvatarMutate,
    isLoading: deleteCandidateAvatarIsLoading,
  } = useDeleteCandidateAvatar()

  /** Process the Delete candidate photo */
  async function deleteCandidatePhoto() {
    deleteCandidateAvatarMutate(
      { Id: candidateProfile.candidateId },
      {
        onSuccess: () => {
          setShowDeleteProfilePictureModal(false)
          getCandidateById()
        },
        onError: ({ response: { data: errData } }: any) => {
          setShowDeleteProfilePictureModal(false)

          Toast({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const onGetCandidateProfileSuccess = (data: GetCandidateByIdResponse) => {
    setImageUrl('')
    dispatch(setCandidateProfile(data.basicCandidateProfile))
  }

  const { refetch: getCandidateById } = useGetCandidateById(
    sessionStorage.getItem('candidateId'),
    onGetCandidateProfileSuccess,
  )

  const CandidateInfoCardClasses = CN(
    `candidate-info-card flex flex-col items-center p-4 bg-white mt-3 md:mt-0 rounded-lg`,
    className,
  )

  return (
    <div className={CandidateInfoCardClasses} {...restProps}>
      <div className='flex items-center flex-col md:flex-row w-full'>
        <div className='flex justify-center items-center md:mr-5 md:mb-0 mb-5'>
          <ProfileAvatar
            isProfilePictureAvailable={
              candidateProfile?.avatarUrl ? true : false
            }
            imageSource={
              candidateProfile?.avatarUrl
                ? candidateProfile?.avatarUrl
                : undefined
            }
            imageContainerClassName='w-[124px] h-[124px]'
            onAddClick={() => {
              setShowEditProfilePictureModal(true)
            }}
            onDeleteClick={() => {
              setShowDeleteProfilePictureModal(true)
            }}
            onEditClick={() => {
              setShowEditProfilePictureModal(true)
            }}
            isStatusDisable={false}
          />
        </div>
        <div className='flex flex-col'>
          <span className='font-Medium text-Gray-800 text-heading-5 mb-1'>
            Hello {candidateName} 👋
          </span>
          <span className='font-Medium text-small text-Gray-800 mb-1'>
            Upload your profile picture
          </span>
          <span className='text-small text-Gray-600 mb-2'>
            Your profile picture will help your referees to identify who you are
            and this is required.
          </span>
          <span className='text-extra-small text-Cobalt-600 font-Medium'>
            * Uploading unprofessional and inappropriate pictures will remove
            your profile from the system{' '}
          </span>
        </div>
      </div>

      {!candidateProfile?.avatarUrl && showCandidateAvatarError && (
        <div className='flex items-center w-full mt-5 bg-Red-50 border border-Red-300 p-4 rounded'>
          <i className='ri-close-circle-fill text-Red-500 text-[18px] mr-[10px]' />
          <span className='text-Red-800 text-[16px] font-Medium'>
            Please upload a profile picture to continue
          </span>
        </div>
      )}

      <CustomizableEditProfilePictureModal
        isActive={showEditProfilePictureModal}
        onHeaderCloseButtonClick={() => {
          setShowEditProfilePictureModal(false)
        }}
        onClickSecondaryBtn={() => {
          setShowEditProfilePictureModal(false)
        }}
        onOverlayClick={() => {
          setShowEditProfilePictureModal(false)
        }}
        onClickPrimaryBtn={() => {
          handleUploadProfileAvatar(files)
        }}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setFiles={setFiles}
        headerTitle={
          candidateProfile?.avatarUrl
            ? 'Change profile photo'
            : 'Add profile photo'
        }
        headerSubTitle='Update your profile photo here.'
        primaryButtonIsLoading={uploadProfilePictureIsLoading}
      />

      {/* delete user profile picture modal */}
      <Modal
        isActive={showDeleteProfilePictureModal}
        onClickPrimaryBtn={() => {
          deleteCandidatePhoto()
        }}
        onClickSecondaryBtn={() => {
          setShowDeleteProfilePictureModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteCandidateAvatarIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowDeleteProfilePictureModal(false)
        }}
        modalProps={{
          className: 'w-[400px] md:w-[466px] bg-white',
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Delete Candidate Profile Picture?
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}

CandidateInfoCard.defaultProps = {}

export default CandidateInfoCard
