import { MutableRefObject, useEffect, useRef } from 'react'

export const useDisableCopyPaste =
  (): MutableRefObject<HTMLDivElement | null> => {
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      const handleCopy = (e: ClipboardEvent) => {
        e.preventDefault()
      }

      const handlePaste = (e: ClipboardEvent) => {
        e.preventDefault()
      }

      const element = ref.current
      if (element) {
        element.addEventListener('copy', handleCopy)
        element.addEventListener('paste', handlePaste)
      }

      return () => {
        if (element) {
          element.removeEventListener('copy', handleCopy)
          element.removeEventListener('paste', handlePaste)
        }
      }
    }, [])

    return ref
  }
