import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface GetInitialAssessmentByIdResponse {
  message: string
  messageTitle: string | null
  isWebCamMandatory: boolean
  duration: any
  privacyPolicyUrl: string | null
}

export const useGetInitialAssessmentDetailsById = (
  id: string | undefined | null,
  onSuccess?: (data: GetInitialAssessmentByIdResponse) => void,
) => {
  return useQuery(
    ['GET_INITIAL_ASSESSMENT_BY_ID', id],
    async () => {
      const data = await get(`/assessment/assessmentresponse/${id}/initialize`)
      return data?.data as GetInitialAssessmentByIdResponse
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching initial assessment data',
          status: 'Error',
        })
      },
      onSuccess,
    },
  )
}
