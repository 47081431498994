import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import TagManager, { TagManagerArgs } from 'react-gtm-module'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'

import { routes } from './routes/routes'

const App: React.FC = () => {
  // const routes = useRoutes()
  const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER || ''
  const tagManagerArgs: TagManagerArgs = {
    gtmId,
  }
  TagManager.initialize(tagManagerArgs)
  /* Render component with or without Layout */
  const renderWithLayout = ({ Component, Layout, ...restProps }: any) => {
    if (Layout) {
      return (
        <Layout {...restProps}>
          <Component />
        </Layout>
      )
    }
    return <Component {...restProps} />
  }

  /* Iterate through Routes */
  const routeRender = (
    <Routes>
      {routes.map(
        (
          {
            redirectTo,
            path,
            layout: Layout,
            component: Component,
            isSecured,
            hasLeftMainContent = true,
            heading,
            subHeading,
            hasSubHeading = true,
            hasSubFooter = false,
            hasPermissions = false,
          }: any,
          key,
        ) => {
          /* If redirectTo is defined, render a Redirect component */
          if (redirectTo) {
            return (
              <Route
                key={key}
                path={path}
                element={<Navigate to={redirectTo} />}
              />
            )
          }

          /* Render Route component */
          return (
            <Route
              path={path}
              key={key}
              element={renderWithLayout({
                Component,
                Layout,
                isSecured,
                hasLeftMainContent,
                heading,
                subHeading,
                hasSubHeading,
                hasSubFooter,
                hasPermissions,
              })}
            />
          )
        },
      )}
    </Routes>
  )

  return <Router>{routeRender}</Router>
}

export default App
