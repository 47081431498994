import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface RequestCareerFormInterface {
  requestCareerFormDetails?: any
}

export const useCreateRequestCareer = () => {
  const mutate = useMutation(
    ({ requestCareerFormDetails }: RequestCareerFormInterface) => {
      return postAPI(`/RequestCareer`, {
        firstName: requestCareerFormDetails?.firstName,
        lastName: requestCareerFormDetails?.lastName,
        email: requestCareerFormDetails?.email,
        phone: requestCareerFormDetails?.phone,
        company: requestCareerFormDetails?.company,
        jobTitle: requestCareerFormDetails?.jobTitle,
        interest: requestCareerFormDetails?.interest,
      })
    },
  )
  return mutate
}

export default useCreateRequestCareer
