export const modifyParagraphOrHeadingSchema = (schema: any) => {
  const modifiedSchema = { ...schema }

  // Iterate through each property to adjust "Paragraph" or "Heading" types
  for (const key in modifiedSchema.properties) {
    if (modifiedSchema.properties[key]) {
      const property = modifiedSchema.properties[key]

      if (property.type == 'object' && property.properties) {
        const videourl = property.properties['videourl']?.description
        const imageurl = property.properties['imageurl']?.description

        if (imageurl) {
          property.properties = { imageurl }
        }

        if (videourl) {
          property.properties = { videourl }
        }
      }
    }
  }

  return modifiedSchema
}
