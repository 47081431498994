import { useState } from 'react'
import CN from 'classnames'

import { ThankingCard, TitleHeader } from 'components/atoms'
import { CareerApplication } from 'components/molecules'

type CareerInterestApplicationProps = {
  [x: string]: any
}

export const CareerInterestApplication = ({
  className,
  ...restProps
}: CareerInterestApplicationProps) => {
  const [isShowCareerApplication, setIsShowCareerApplication] = useState(true)
  const [isShowThankingCard, setIsShowThankingCard] = useState(false)

  const TalentRequestFormClasses = CN(
    'talentRequestFormClasses-form mt-5 sm:mt-0',
    className,
    {},
  )

  return (
    <div className={TalentRequestFormClasses} {...restProps}>
      {isShowCareerApplication && (
        <>
          <TitleHeader
            title='Career Interest Application'
            subTitle='Please fill below form and send it to us. We will get back to you shortly.'
          />

          <CareerApplication
            setIsShowThankingCard={setIsShowThankingCard}
            setIsShowCareerApplication={setIsShowCareerApplication}
          />
        </>
      )}

      {isShowThankingCard && (
        <ThankingCard
          title='Thank you!'
          description='We got your talent request and one of our team members will reach you within next 48 hours'
        />
      )}
    </div>
  )
}
