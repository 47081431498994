import { cn } from 'utils'

type ImageViewerProps = {
  imageUrl: string
}
const ImageViewer = ({ imageUrl }: ImageViewerProps) => {
  return (
    <div className='w-1/2'>
      <img
        src={imageUrl}
        alt='drag-and-drop-area'
        className={cn('rounded h-[140px] w-11/12')}
      />
    </div>
  )
}

export default ImageViewer
