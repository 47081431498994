import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useRequestPhoneRefCheck } from 'framework/api/methods'

import { PageLoader, ThankingCard } from 'components/atoms'
import { capitalizeFirstLetter } from 'utils'

type PhoneRefCheckThankYouProps = {
  [x: string]: any
}

export const PhoneRefCheckThankYou = ({
  className,
  ...restProps
}: PhoneRefCheckThankYouProps) => {
  const { pathname: locationPath } = useLocation()

  const candidateId = locationPath.split('/')[3]
  const refereeId = locationPath.split('/')[5]

  const {
    mutateAsync: requestPhoneRefCheck,
    isLoading: isLoadingPhoneRefCheckRequest,
  } = useRequestPhoneRefCheck(candidateId, refereeId)

  useEffect(() => {
    const timer = setTimeout(() => {
      requestPhoneRefCheck()
    }, 2000)
    return () => clearTimeout(timer)
  }, [])

  const ThankYouClasses = CN(`thank-you flex flex-col gap-y-5`, className)

  const { IsCareerInterest, IsStaffRequest } = useSelector(
    (state: any) => state.layout,
  )

  const { name: agencyName } =
    useSelector((state: any) => state.candidate.agencyData) || {}

  const navigate = useNavigate()

  return (
    <>
      {isLoadingPhoneRefCheckRequest && <PageLoader size='xs' />}

      <div className={ThankYouClasses} {...restProps}>
        <ThankingCard
          title='Thank you!'
          description="We have received your phone reference check request and
          have informed the candidate's managing consultant"
        />

        <div className='flex flex-col md:flex-row gap-5'>
          {/** Left Side card below the Thank You Card */}
          {IsStaffRequest && (
            <div className='flex bg-[#F2F9FF] border-[1px] border-Cobalt-100 rounded-lg md:w-1/2 w-full'>
              <div className='flex flex-col gap-y-5 p-4 justify-between h-full'>
                <div className='flex flex-col gap-y-2'>
                  <span className='font-SemiBold text-heading-4 text-Cobalt-600'>
                    Are you looking for staff?
                  </span>

                  <span
                    className='font-Regular text-small text-Gray-800'
                    style={{ whiteSpace: 'pre-wrap' }}>
                    Our company provides a range of staffing solutions to
                    companies like yours. We offer experienced and reliable
                    staff to meet your hiring needs.
                    <br />
                    <br />
                    If you are currently seeking to fill vacancies in your
                    organization, we would be delighted to assist you in finding
                    the ideal candidate. Please don't hesitate to request talent
                    by clicking on the button below: <br />
                  </span>
                </div>

                <Button
                  className=''
                  onClick={() => {
                    navigate('/candidate/:id/talent-request')
                  }}
                  textTheme='blue'
                  theme='white'>
                  Request Talent Now
                  <i className='ri-arrow-right-line text-[18px] text-Cobalt-600 ml-2' />
                </Button>
              </div>
            </div>
          )}

          {IsCareerInterest && (
            <div className='flex bg-[#F2F9FF] border-[1px] border-Cobalt-100 rounded-lg md:w-1/2 w-full'>
              <div className='flex flex-col gap-y-5 p-4 justify-between h-full'>
                <div className='flex flex-col gap-y-2'>
                  <span className='font-SemiBold text-heading-4 text-Cobalt-600'>
                    We're hiring!
                  </span>

                  <span
                    className='font-Regular text-small text-Gray-800'
                    style={{ whiteSpace: 'pre-wrap' }}>
                    We have exciting openings at{' '}
                    {capitalizeFirstLetter(agencyName || '')}! To discover more
                    about the exciting opportunities available, please take a
                    moment to fill out the form below.
                  </span>
                </div>

                <Button
                  className=''
                  onClick={() => {
                    navigate('/candidate/:id/career-interest-application')
                  }}
                  textTheme='blue'
                  theme='white'>
                  Yes, I'm Interested
                  <i className='ri-arrow-right-line text-[18px] text-Cobalt-600 ml-2' />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
