import { FC } from 'react'
import labourhubLoading from 'assets/animations/refhub-loading.json'
import CN from 'classnames'
import { useLottie } from 'lottie-react'

export interface PageLoaderProps {
  className?: string | undefined
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | undefined
}

export const PageLoader: FC<PageLoaderProps> = ({
  size,
  className,
}: PageLoaderProps) => {
  const AnimatedView = () => {
    const options = {
      animationData: labourhubLoading,
      loop: true,
      autoplay: true,
      speed: 5,
    }

    const { View } = useLottie(options)

    return View
  }
  return (
    <div
      className={CN(
        'flex w-full h-full items-center justify-center absolute z-[99] left-0 top-0 bg-white bg-opacity-70 cursor-not-allowed',
        className,
      )}>
      <div
        className={CN('', {
          'w-full': size === 'lg',
          'w-1/2': size === 'md',
          'w-1/4': size === 'sm',
          'w-1/6': size === 'xs',
          'w-1/12': size === 'xxs',
        })}>
        <AnimatedView />
      </div>
    </div>
  )
}

PageLoader.defaultProps = {
  className: undefined,
  size: 'xs',
}

export default PageLoader
