import { useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import { FeedbackType, RefereeFeedbackSurveyModal } from 'features/misc'

import { ThankingCard, TitleHeader } from 'components/atoms'
import {
  AddRefereesDetailsCard,
  AddRefereesProgressCard,
  CandidateInfoCard,
} from 'components/molecules'

type CandidateFormProps = {
  [x: string]: any
}

export const CandidateForm = ({
  className,
  ...restProps
}: CandidateFormProps) => {
  const [showRefereeFeedbackModalOpen, setShowRefereeFeedbackModalOpen] =
    useState(false)

  const CandidateFormClasses = CN(
    'candidate-form mt-5 md:mt-0 pb-5',
    className,
    {},
  )

  const [isCompleted, setIsCompleted] = useState(false)

  const { currentReferee, candidateProfile } = useSelector(
    (state: any) => state.candidate,
  )

  return (
    <div className={CandidateFormClasses} {...restProps}>
      <TitleHeader
        title='Request Referees'
        subTitle={`Hello there! You are required to provide ${
          candidateProfile?.remainingRefereeCount || 1
        } referrals in order to
          apply for the ${candidateProfile?.jobTitle || ''} Vacancy.`}
      />
      <CandidateInfoCard
        candidateName={candidateProfile?.name}
        candidateProfile={candidateProfile}
      />
      <AddRefereesProgressCard
        isCompleted={isCompleted}
        currentReferee={currentReferee}
        totalReferees={candidateProfile?.remainingRefereeCount || 1}
      />

      {isCompleted ? (
        <>
          <div className='mt-3'>
            <ThankingCard
              title='Thank you for Submitting Referees!'
              description='We will get back to you shortly'
            />
          </div>
          {showRefereeFeedbackModalOpen && (
            <RefereeFeedbackSurveyModal
              isModalActive={showRefereeFeedbackModalOpen}
              setIsModalActive={setShowRefereeFeedbackModalOpen}
              feedbackType={FeedbackType.CANDIDATE_FEEDBACK}
            />
          )}
        </>
      ) : (
        <AddRefereesDetailsCard
          maxReferees={candidateProfile?.remainingRefereeCount || 1}
          setIsCompleted={setIsCompleted}
          setShowRefereeFeedbackModalOpen={setShowRefereeFeedbackModalOpen}
        />
      )}
    </div>
  )
}
