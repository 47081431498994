import thankYou from 'assets/images/thankYou.svg'

export type ThankingCardWithHeaderProps = {
  title: string
  description: string
  headerTitle: string
  headerDescription: string
  [x: string]: any
}

export const ThankingCardWithHeader = ({
  title,
  description,
  headerTitle,
  headerDescription,
  ...restProps
}: ThankingCardWithHeaderProps) => {
  return (
    <>
      <div className='w-full flex flex-col justify-center bg-white rounded-xl h-24 max-md:mt-5 mb-1 px-4'>
        <span className='text-Gray-800 font-SemiBold text-heading-4 mb-1'>
          {headerTitle}
        </span>
        <span className='text-Gray-600 font-Regular text-small'>
          {headerDescription}
        </span>
      </div>

      <div className='w-full h-[400px] bg-white rounded-xl' {...restProps}>
        <div className='mx-auto pb-[49px]'>
          <div className='flex justify-center items-center md:flex flex-col'>
            <div className='md:flex mt-12 mb-4 h-[242px]'>
              <img src={thankYou} alt='' className='' />
            </div>

            <div className='md:flex justify-center font-Medium text-heading-5 text-Gray-800 mx-[11px] text-center'>
              {title}
            </div>

            <div className='md:flex justify-center font-Regular text-small text-Gray-600 mt-1 mx-[11px] text-center'>
              {description}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
