import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { AppSettingProps } from 'features/settings'
import { get } from 'framework/api/http'

export const useGetAgencySettingsByTenantId = (
  tenantId: string,
  onSuccess: (agencySettings: AppSettingProps[]) => void,
) => {
  return useQuery(
    ['APPSETTINGS_AGENCIES', tenantId],
    async () => {
      if (tenantId) {
        const res = await get(`/AppSettings/Agencies/${tenantId}`)
        return res.data.agencySettings as AppSettingProps[]
      }
      return []
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error',
          alertBody: 'Unable to fetch agency settings',
          status: 'Error',
        })
      },
    },
  )
}
