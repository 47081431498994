import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { postAPI } from 'framework/api/http'

type SubmitCandidateSurveyRequest = {
  candidateId: string
  surveyId: string
  surveyResponseId: string
  jsonData: string | null
}

export const useSubmitCandidateSurvey = () => {
  return useMutation(
    ({
      candidateId,
      surveyId,
      surveyResponseId,
      jsonData,
    }: SubmitCandidateSurveyRequest) => {
      return postAPI(`/candidates/${candidateId}/surveys/${surveyId}/submit`, {
        surveyResponseId,
        jsonData,
      })
    },
    {
      onError: () => {
        Toast({
          alertHeader: `Error submitting survey`,
          status: 'Error',
        })
      },
    },
  )
}
