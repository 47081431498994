import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { postAPI } from 'framework/api/http'

export interface Referee {
  firstName: string
  phone: string
  countryCode: string
  lastName: string
  email: string
  company: string
  jobTitle: string
  candidateCompany?: string
  candidateRelation?: string
  candidatePosition?: string
  startDate?: string
  endDate?: string
  isCurrentlyWorking?: boolean
  referenceType?: ReferenceCheckType[] | null
}

interface ReferenceCheckType {
  note: string
  type: string
  specificEmployer: string
  isSubmitted: boolean
}

interface AddRefereesProps {
  candidateId: string | null
  referees: Referee[]
  referenceType?: ReferenceCheckType[] | null
}

export const useAddReferees = () => {
  return useMutation(
    ({ candidateId, referees, referenceType }: AddRefereesProps) => {
      const todayDate = new Date().toISOString().slice(0, 10)

      // Loop through the array and update the dates
      for (let i = 0; i < referees.length; i++) {
        if (referees[i].startDate === '') {
          referees[i].startDate = todayDate
        }
        if (referees[i].endDate === '') {
          referees[i].endDate = todayDate
        }
      }

      const mergedArray = referees.map((item, index) => {
        return {
          ...item,
          referenceType: referenceType && referenceType[index],
        }
      })

      return postAPI(
        `/facade/Candidate/${candidateId}/Referee/AddWizard`,
        mergedArray,
      )
    },
    {
      onError: ({ response: { data: errData } }: any) => {
        Toast({
          alertHeader: 'Error...!',
          alertBody: errData?.message,
          status: 'Error',
        })
      },
    },
  )
}

export default useAddReferees
