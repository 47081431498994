import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface useCandidateTokenInterface {
  candidateToken: string
}

export const useCandidateToken = () => {
  const mutate = useMutation(
    ({ candidateToken }: useCandidateTokenInterface) => {
      return postAPI('/identity/CandidateToken', {
        candidateToken,
      })
    },
  )

  return mutate
}

export default useCandidateToken
