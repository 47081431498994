import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  Checkbox,
  Modal,
  Radio,
  TextField,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { format, parse } from 'date-fns'
import { Formik } from 'formik'
import {
  useDeclineRefCheck,
  useGetCandidateById,
  useGetRefereeDetailsById,
  useUpdateCorrectInformation,
} from 'framework/api/methods'
import moment from 'moment'
import { boolean, date, object, ref, string } from 'yup'

import { CalendarInput, PageLoader, TitleHeader } from 'components/atoms'

type IncorrectInformationProps = {
  [x: string]: any
}

export const IncorrectInformation = ({
  className,
  ...restProps
}: IncorrectInformationProps) => {
  const IncorrectInformationClasses = CN(
    'candidate-form mt-5 sm:mt-0',
    className,
    {},
  )
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState<boolean>(false)

  const incorrectInformationSchema = object({
    jobTitle: string().required(),
    employmentStartDate: date()
      .required()
      .typeError('Invalid Employment Start Date.')
      .max(
        ref('employmentEndDate'),
        ({ max }) =>
          `Date needs to be before ${moment(max).format('DD/MM/YYYY')}!`,
      ),
    employmentEndDate: isCurrentlyWorking
      ? date().optional()
      : date()
          .required()
          .typeError('Invalid Employment End Date.')
          .min(
            ref('employmentStartDate'),
            ({ min }) =>
              `Date needs to be after ${moment(min).format('DD/MM/YYYY')}!`,
          ),
    relationShip: string().required(),
    isCurrentlyWorking: boolean().default(false),
  })

  const { pathname: locationPath } = useLocation()
  const navigate = useNavigate()

  const candidateId = locationPath.split('/')[2]
  const refereeId = locationPath.split('/')[4]

  const { mutateAsync: declineRefCheck, isLoading: isLoadingDeclineRefCheck } =
    useDeclineRefCheck(candidateId, refereeId)

  const {
    mutateAsync: correctIncorrectInformation,
    isLoading: isUpdatingIncorrectInformation,
  } = useUpdateCorrectInformation(candidateId, refereeId)
  const { data: refereeDetails, isLoading: loadingRefereeDetailsById } =
    useGetRefereeDetailsById(candidateId, refereeId)
  const { data: candidateInfo, isLoading: isLoadingCandidateInfo } =
    useGetCandidateById(sessionStorage.getItem('candidateId'))

  /** Form Fields of incorrect information */
  const [isWorkColleague, setIsWorkColleague] = useState(true)

  const [isOpenDeclineCheck, setIsOpenDeclineCheck] = useState(false)

  return (
    <div className={IncorrectInformationClasses} {...restProps}>
      {loadingRefereeDetailsById ||
        isLoadingCandidateInfo ||
        (isUpdatingIncorrectInformation && <PageLoader size='xxs' />)}
      {/* Decline Referee modal */}
      <Modal
        isActive={isOpenDeclineCheck}
        onClickPrimaryBtn={() => {
          declineRefCheck()
            .then(() => {
              navigate('thankyou')
            })
            .finally(() => {
              setIsOpenDeclineCheck(false)
            })
        }}
        className={'p-4'}
        onClickSecondaryBtn={() => {
          setIsOpenDeclineCheck(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: isLoadingDeclineRefCheck,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsOpenDeclineCheck(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Close'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Are you sure you want to Decline?
          </span>

          {/* <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            By claiming this person is not your work colleague, you decline the
            reference check
          </span> */}
        </div>
      </Modal>
      <TitleHeader
        title={`Provide Correct Information of ${candidateInfo?.basicCandidateProfile?.name}`}
        subTitle={`Please be kind enough to correct the details of the time when ${candidateInfo?.basicCandidateProfile?.firstName} was working in your company`}
      />
      <div className='flex flex-col gap-4 p-4 mt-5 bg-white'>
        <div className='text-small font-Medium text-Gray-800'>
          Was {candidateInfo?.basicCandidateProfile?.firstName} your work
          colleague?
        </div>
        <div className='flex gap-6'>
          <Radio
            id='Yes'
            labelText='Yes'
            defaultChecked={true}
            helperText=''
            name='isWorkingColleague'
            onChange={() => {
              setIsWorkColleague(true)
            }}
          />
          <Radio
            id='No'
            labelText='No'
            helperText=''
            name='isWorkingColleague'
            defaultChecked={false}
            onChange={() => {
              setIsWorkColleague(false)
            }}
          />
        </div>
        {!isLoadingCandidateInfo && (
          <Formik
            validationSchema={incorrectInformationSchema}
            initialValues={{
              jobTitle: refereeDetails?.referee.jobTitle || '',
              employmentStartDate: refereeDetails?.referee?.startDate
                ? new Date(refereeDetails?.referee?.startDate).toISOString()
                : new Date().toISOString(),
              employmentEndDate: refereeDetails?.referee?.endDate
                ? new Date(refereeDetails?.referee?.endDate).toISOString()
                : new Date().toISOString(),
              relationShip: refereeDetails?.referee?.candidateRelation || '',
              isCurrentlyWorking:
                refereeDetails?.referee?.isCurrentlyWorking || false,
            }}
            onSubmit={(values) => {
              correctIncorrectInformation({
                jobTitle: values.jobTitle,
                candidateRelation: values.relationShip,
                startDate: moment(values.employmentStartDate).format(
                  'YYYY-MM-DD',
                ),
                endDate: moment(values.employmentEndDate).format('YYYY-MM-DD'),
                isCurrentlyWorking,
              }).then(() => {
                navigate(
                  `/candidate/${location.pathname.split('/')[2]}/referee/${
                    location.pathname.split('/')[4]
                  }/questionnaire`,
                )
              })
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isValid,
              /* and other goodies */
            }) => (
              <>
                {isWorkColleague && (
                  <div className='grid gap-4 lg:grid-cols-2 sm:grid-cols-1'>
                    <TextField
                      isRequired
                      label='Job Title'
                      value={values.jobTitle}
                      isErrored={errors.jobTitle && touched.jobTitle}
                      onChange={handleChange('jobTitle')}
                      onBlur={handleBlur('jobTitle')}
                      placeholder=''
                    />
                    <TextField
                      isRequired
                      label='Relationship to you'
                      isErrored={errors.relationShip && touched.relationShip}
                      onChange={handleChange('relationShip')}
                      onBlur={handleBlur('relationShip')}
                      value={values.relationShip}
                      placeholder=''
                    />
                    <div>
                      <span className='flex text-small text-Gray-800 font-Medium'>
                        Employment started on
                      </span>
                      <CalendarInput
                        value={values.employmentStartDate}
                        formatDate={(date) => format(date, 'dd/MM/yyyy')}
                        parseDate={(str) =>
                          parse(str, 'dd/MM/yyyy', new Date())
                        }
                        className='mt-1'
                        onChange={(date) => {
                          setFieldValue('employmentStartDate', date)
                        }}
                        isErrored={errors.employmentStartDate ? true : false}
                        disableFutureDates={true}
                        helperText={
                          errors.employmentStartDate
                            ? errors.employmentStartDate
                            : ''
                        }
                      />
                    </div>
                    <div>
                      <span className='flex text-small text-Gray-800 font-Medium'>
                        Employment ended on
                      </span>
                      <CalendarInput
                        value={values.employmentEndDate}
                        formatDate={(date) => format(date, 'dd/MM/yyyy')}
                        parseDate={(str) =>
                          parse(str, 'dd/MM/yyyy', new Date())
                        }
                        disabled={values.isCurrentlyWorking}
                        className='mt-1'
                        onChange={(date) => {
                          setFieldValue('employmentEndDate', date)
                        }}
                        isErrored={errors.employmentEndDate ? true : false}
                        helperText={
                          errors.employmentEndDate
                            ? errors.employmentEndDate
                            : ''
                        }
                      />
                    </div>
                    <div className='flex flex-col mb-0 md:flex-row'>
                      <Checkbox
                        labelText='I am currently working in this role'
                        labelTextClassName='font-Regular'
                        checkboxClassName='mt-0.5'
                        checked={values.isCurrentlyWorking}
                        onChange={(e: any) => {
                          setFieldValue('isCurrentlyWorking', e.target.checked)
                          setIsCurrentlyWorking(e.target.checked)
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className='grid gap-4 md:flex pb-9 md:pb-0'>
                  <Button
                    isDisabled={isWorkColleague ? !isValid : false}
                    onClick={
                      isWorkColleague
                        ? handleSubmit
                        : () => {
                            setIsOpenDeclineCheck(true)
                          }
                    }>
                    {isWorkColleague
                      ? 'Save Details & Continue'
                      : 'Decline to Answer'}
                  </Button>
                  <Button
                    textTheme='blue'
                    theme='white'
                    onClick={() => {
                      navigate(-1)
                    }}>
                    Go Back
                  </Button>
                  {/* {isWorkColleague && (
                    <Button
                      textTheme='blue'
                      theme='clear'
                      className='md:absolute md:right-0'
                      onClick={() => {
                        requestPhoneRefCheck().then(() => {
                          navigate('thankyou')
                        })
                      }}>
                      I Prefer Phone Ref Check
                    </Button>
                  )} */}
                </div>
              </>
            )}
          </Formik>
        )}
      </div>
    </div>
  )
}
