/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from '@labourhub/labour-hub-ds'
import thankyouCheckMark from 'assets/animations/token-expired.json'
import RefHubLogo from 'assets/images/refHubLogo.svg'
import CN from 'classnames'
import { useLottie } from 'lottie-react'

type TokenExpiredProps = {
  [x: string]: any
}

export const TokenExpired = ({
  className,
  ...restProps
}: TokenExpiredProps) => {
  const TokenExpiredClasses = CN(
    `token-expired px-4 pt-5 md:px-[265px] md:pt-[40px] w-full`,
    className,
  )

  const AnimatedView = () => {
    const options = {
      animationData: thankyouCheckMark,
      loop: true,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  return (
    <div className={TokenExpiredClasses} {...restProps}>
      <img className='p-[6px] h-[40px] mb-5' src={RefHubLogo} alt='Logo' />
      <div className='w-full bg-white rounded-xl'>
        <div className='mx-auto pb-[49px]'>
          <div className='flex justify-center items-center md:flex flex-col'>
            <div className='w-[133px] md:flex mt-6 mx-[49px]'>
              <AnimatedView />
            </div>

            <div className='md:flex justify-center font-Medium text-heading-5 text-Gray-800 mx-[11px] text-center'>
              Sorry, there was a problem!
            </div>

            <div className='md:flex justify-center font-Regular text-small text-Gray-600 mt-1 mx-[11px] text-center'>
              Looks like the link you accessed is expired
            </div>

            <Button
              className='mt-5'
              iconLeft={<i className='ri-customer-service-line text-[18px]' />}
              onClick={() => {
                window.open(
                  'https://www.refhub.com.au/help-center',
                  '_blank',
                  'noopener,noreferrer',
                )
              }}>
              Contact Support
            </Button>
          </div>
        </div>
      </div>
      <div className='w-full bg-white rounded-xl flex flex-col md:flex-row justify-center items-center md:justify-between py-4 px-[34px] mt-5'>
        <span
          onClick={() => {
            window.open(
              'https://www.refhub.com.au/',
              '_blank',
              'noopener,noreferrer',
            )
          }}
          className='text-extra-small font-Light text-[#6B7280] mb-1 md:mb-0 whitespace-nowrap cursor-pointer'>
          Powered by{' '}
          <span className='font-Medium whitespace-nowrap'>RefHub</span>
        </span>
        <div className='flex flex-col md:flex-row items-center justify-center md:justify-start'>
          <span className='whitespace-nowrap text-Gray-800 text-extra-small mr-2  mb-1 md:mb-0'>
            {`Copyright © ${new Date().getFullYear()} RefHub. All rights reserved.`}
          </span>
          <a
            target='_blank'
            href='https://www.refhub.com.au/privacy-policy'
            className='whitespace-nowrap text-Cobalt-600 font-Medium text-extra-small'>
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  )
}
