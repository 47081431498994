import React, { FC, useEffect, useState } from 'react'
import { Button, TextArea, TextField, Toast } from '@labourhub/labour-hub-ds'
import { useCreateRequestCareer } from 'framework/api/methods'

import { PhoneInputField } from 'components/atoms'
import { isEmail, isLetters } from 'utils'

export interface CareerApplicationProps {
  [x: string]: any
}

export const CareerApplication: FC<CareerApplicationProps> = ({
  setIsShowCareerApplication,
  setIsShowThankingCard,
  ...restProps
}: CareerApplicationProps) => {
  const [requestCareerFormDetails, setRequestCareerFormDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    jobTitle: '',
    interest: '',
  })

  const notify = (props: any) => Toast(props)

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    company: false,
    jobTitle: false,
    interest: false,
  })

  /** front end validation for all input fields */
  const validateInputs = () => {
    const { firstName, lastName, email } = requestCareerFormDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) ? false : true,
      lastName: isLetters(lastName) ? false : true,
      email: isEmail(email) ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) && isLetters(lastName) && isEmail(email)

    return isAllValidFields
  }

  /** primary button disability handler */
  useEffect(() => {
    const { firstName, lastName, email } = requestCareerFormDetails || {}
    ;(firstName && lastName && email ? false : true)
      ? setIsSubmitButtonDisabled(true)
      : setIsSubmitButtonDisabled(false)
  }, [requestCareerFormDetails])

  /** APi call for the submit request career form */
  const { mutate: submitRequestCareerFormMutate } = useCreateRequestCareer()

  /** Process the submit request career form */
  const submitRequestCareerForm = () => {
    submitRequestCareerFormMutate(
      {
        requestCareerFormDetails,
      },
      {
        onSuccess: () => {
          setIsShowCareerApplication(false)
          setIsShowThankingCard(true)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle submit the request career form */
  const onSubmitRequestCareerForm = () => {
    const valid = validateInputs()

    if (valid) {
      submitRequestCareerForm()
    } else {
      notify({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  return (
    <div className='pt-6 talent-request-form' {...restProps}>
      <div className='flex flex-col w-full h-auto pl-4 pr-[17px] overflow-y-auto rounded-lg bg-white pb-4'>
        <div className='flex flex-col md:flex-row'>
          <TextField
            className='flex-1 mt-5 md:mr-5'
            label='First Name'
            isRequired
            value={requestCareerFormDetails.firstName}
            onChange={(e: any) => {
              setRequestCareerFormDetails({
                ...requestCareerFormDetails,
                firstName: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.firstName}
          />

          <TextField
            className='flex-1 mt-5'
            label='Last Name'
            isRequired
            value={requestCareerFormDetails.lastName}
            onChange={(e: any) => {
              setRequestCareerFormDetails({
                ...requestCareerFormDetails,
                lastName: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.lastName}
          />
        </div>

        <div className='flex flex-col md:flex-row'>
          <TextField
            className='flex-1 mt-5 md:mr-5'
            label='Your Email'
            isRequired
            value={requestCareerFormDetails.email}
            onChange={(e: any) => {
              setRequestCareerFormDetails({
                ...requestCareerFormDetails,
                email: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.email}
          />

          <PhoneInputField
            className='flex-1 mt-5'
            label='Your Phone'
            value={requestCareerFormDetails?.phone}
            onChange={(number: any) =>
              setRequestCareerFormDetails({
                ...requestCareerFormDetails,
                phone: number,
              })
            }
          />
        </div>

        <div className='flex flex-col md:flex-row'>
          <TextField
            className='flex-1 mt-5 md:mr-5'
            label='Current Company'
            value={requestCareerFormDetails.company}
            onChange={(e: any) => {
              setRequestCareerFormDetails({
                ...requestCareerFormDetails,
                company: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.company}
          />

          <TextField
            className='flex-1 mt-5'
            label='Current Position'
            value={requestCareerFormDetails.jobTitle}
            onChange={(e: any) => {
              setRequestCareerFormDetails({
                ...requestCareerFormDetails,
                jobTitle: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.jobTitle}
          />
        </div>

        <div className='flex flex-col mt-5 md:flex-row'>
          <TextArea
            className='flex-1'
            value={requestCareerFormDetails?.interest}
            onChange={(e: any) => {
              setRequestCareerFormDetails({
                ...requestCareerFormDetails,
                interest: e.target.value,
              })
            }}
            label='Briefly mention your career interests'
            placeholder=''
            rows={4}
          />
        </div>

        <div className='flex flex-col mt-8 md:flex-row md:mb-2'>
          <Button
            className='group flex-1 md:mr-[330px] md:mb-0 md:mt-0'
            isDisabled={isSubmitButtonDisabled}
            onClick={() => {
              onSubmitRequestCareerForm()
            }}>
            Submit Application
            <i className='ri-arrow-right-line text-[16px] text-white ml-2 hidden group-hover:block' />
          </Button>

          <div className='flex-1' />
        </div>
      </div>
    </div>
  )
}

CareerApplication.defaultProps = {}

export default CareerApplication
