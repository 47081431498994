import { FC } from 'react'
import CN from 'classnames'

export interface TitleHeaderProps {
  title: string
  subTitle: string
  [x: string]: any
}

export const TitleHeader: FC<TitleHeaderProps> = ({
  className,
  title,
  subTitle,
  ...restProps
}: TitleHeaderProps) => {
  const TitleHeaderClasses = CN(
    `title-header flex flex-col p-4 bg-white rounded-lg w-full mb-3`,
    className,
  )

  return (
    <div className={TitleHeaderClasses} {...restProps}>
      <span className='text-Gray-800 font-SemiBold text-[20px] mb-1'>
        {title}
      </span>
      <span className='text-Gray-600 font-[14px]'>{subTitle}</span>
    </div>
  )
}

TitleHeader.defaultProps = {}

export default TitleHeader
