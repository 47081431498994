/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import RefHubLogo from 'assets/images/refHubLogo.svg'
import CN from 'classnames'

import { ThankingCard } from 'components/atoms'

type AlreadySubmittedProps = {
  [x: string]: any
}

export const AlreadySubmitted = ({
  className,
  ...restProps
}: AlreadySubmittedProps) => {
  const AlreadySubmittedClasses = CN(
    `already-submitted px-4 pt-5 md:px-[265px] md:pt-[40px] w-full`,
    className,
  )

  return (
    <div className={AlreadySubmittedClasses} {...restProps}>
      <img className='p-[6px] h-[40px] mb-5' src={RefHubLogo} alt='Logo' />
      <ThankingCard
        title='Thank You, You have already completed this form'
        description='Thank you for taking your time and sending your feedback to us.'
      />

      <div className='w-full bg-white rounded-xl flex flex-col md:flex-row justify-center items-center md:justify-between py-4 px-[34px] mt-5'>
        <span
          onClick={() => {
            window.open(
              'https://www.refhub.com.au/',
              '_blank',
              'noopener,noreferrer',
            )
          }}
          className='text-extra-small font-Light text-[#6B7280] mb-1 md:mb-0 whitespace-nowrap cursor-pointer'>
          Powered by{' '}
          <span className='font-Medium whitespace-nowrap'>RefHub</span>
        </span>
        <div className='flex flex-col md:flex-row items-center justify-center md:justify-start'>
          <span className='whitespace-nowrap text-Gray-800 text-extra-small mr-2  mb-1 md:mb-0'>
            {`Copyright © ${new Date().getFullYear()} RefHub. All rights reserved.`}
          </span>
          <a
            target='_blank'
            href='https://www.refhub.com.au/privacy-policy'
            className='whitespace-nowrap text-Cobalt-600 font-Medium text-extra-small'>
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  )
}
