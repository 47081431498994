import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import JSONForm from '@rjsf/core'
import { useGetMappedVariables } from 'api'
import RefHubLogo from 'assets/images/refHubLogo.svg'
import { useSubmitAssessment } from 'framework/api/methods/assessment/submitAssessment'
import { useGetDynamicAssessmentForm } from 'framework/api/methods/assessment/useGetDynamicAssessmentForm'
import { GetInitialAssessmentByIdResponse } from 'framework/api/methods/assessment/useGetInitialAssessmentDetailsById'
import { useDisableCopyPaste, useFullscreen } from 'hooks'

import { PageLoader } from 'components/atoms'
import {
  FormBuilder,
  modifyParagraphOrHeadingSchema,
} from 'components/molecules'

import useOutsideMouseTracker from '../hook/useOutsideMouseTracker'
import ProgressBar from '../ProgressBar/ProgressBar'
import Timer, { TimerHandle } from '../Timer/Timer'

import ExitFullScreenModal from './ExitFullScreenModal'

type QuestionsProps = {
  initialAssessment: GetInitialAssessmentByIdResponse | undefined
  maxSnaps: number
  captureImage: () => void
  handleAssessmentDecline: () => void
  isLoadingDeclineAssessment: boolean
  stopWebcam: () => void
  [x: string]: any
}
const Questions = ({
  className,
  initialAssessment,
  maxSnaps,
  captureImage,
  handleAssessmentDecline,
  isLoadingDeclineAssessment,
  stopWebcam,
}: QuestionsProps) => {
  const disableCopyPasteRef = useDisableCopyPaste()
  const { showFullscreenWarningModal, enterFullscreen, exitFullscreen } =
    useFullscreen()

  const { cid: candidateId, aid: assessmentId } = useParams()
  const [searchParams] = useSearchParams()
  const assessmentResponseId = searchParams.get('assessmentResponseId')
  const [dynamicFieldMapping, setDynamicFieldMapping] = useState<Record<
    string,
    string
  > | null>(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [formData, setFormData] = useState({})
  const componentRef = useRef<HTMLDivElement>(null)
  const dynamicFormRef = useRef<JSONForm<any, any> | null>(null)
  const [dynamicFormSchema, setDynamicFormSchema] = useState<any>(null)
  const [totalAssessmentCount, setTotalAssessmentCount] = useState(0)
  const [totalSteps, setTotalSteps] = useState(0)
  const [isFieldValid, setIsFieldValid] = useState(false)
  const [formattedTime, setFormattedTime] = useState<any>(null)
  const [propertyKey, setPropertyKey] = useState<any>(null)

  const [formKey, setFormKey] = useState(0)
  const timerRef = useRef<TimerHandle>(null)

  const navigate = useNavigate()

  useGetMappedVariables({ candidateId: candidateId ?? '' }, (data) => {
    setDynamicFieldMapping(data)
  })

  const { data: dynamicForm, isLoading: isLoadingDynamicForm } =
    useGetDynamicAssessmentForm(assessmentId ?? '')

  useEffect(() => {
    const handleTextareaInput = (event) => {
      const textarea = event.target
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
      textarea.style.marginBottom = '12px'
    }

    const textareas = componentRef.current
      ?.querySelector('form.jason-theme')
      ?.querySelectorAll('textarea')

    if (textareas) {
      textareas.forEach((textarea) => {
        if (textarea instanceof HTMLTextAreaElement) {
          textarea.rows = 5
          textarea.addEventListener('input', handleTextareaInput)
        }
      })
    }

    return () => {
      if (textareas) {
        textareas.forEach((textarea) => {
          if (textarea instanceof HTMLTextAreaElement) {
            textarea.removeEventListener('input', handleTextareaInput)
          }
        })
      }
    }
  }, [dynamicForm])

  useEffect(() => {
    const updateNode = () => {
      if (!componentRef.current) {
        return
      }
      const containers = Array.from(
        componentRef.current.querySelectorAll('.append-class-container'),
      )

      const elementsToAppend = Array.from(
        componentRef.current.querySelectorAll(
          '.form-control, .checkboxes, .field-radio-group',
        ),
      )

      if (containers.length > 0 && elementsToAppend.length > 0) {
        elementsToAppend.forEach((element, index) => {
          const containerIndex = index % containers.length
          const container = containers[containerIndex]

          if (element && container) {
            if (!container.contains(element)) {
              element.classList.add('w-full')

              try {
                container.appendChild(element)
              } catch (error) {
                console.error('Error appending element:', error)
              }
            }
          }
        })
      } else {
        console.log('No containers or elements to append found')
      }
    }

    updateNode()
  }, [dynamicForm, currentStep, dynamicFormSchema, propertyKey])

  useEffect(() => {
    if (dynamicForm) {
      //   dynamicAssessmentRefetch()
      setDynamicFormSchema(changeJsonSchema(JSON.parse(dynamicForm.jsonSchema)))
    }
  }, [dynamicForm, currentStep])

  useEffect(() => {
    setFormKey((prevKey) => prevKey + 1)
  }, [dynamicForm, currentStep])

  const changeJsonSchema = (schema) => {
    const properties = { ...schema.properties }
    const propertiesKey = Object.keys(properties)
    const propertiesValue = Object.values(properties)
    const filteredProperties =
      propertiesValue &&
      propertiesValue.filter((obj: any) => obj['title'] !== '')
    setTotalAssessmentCount(propertiesKey.length)
    setTotalSteps(filteredProperties.length)
    const currentFieldKey = Object.keys(properties)[currentStep]
    const isRequired = schema.required?.includes(currentFieldKey)
    setIsFieldValid(!isRequired)

    let tempIndex = 0

    if (dynamicFieldMapping) {
      Object.keys(properties).forEach((key) => {
        const property = properties[key]

        Object.entries(dynamicFieldMapping).forEach(([code, value]) => {
          if (property?.title !== '') {
            property.title = property.title.replaceAll(`@${code}`, value)
          }

          if (property?.title === '' && property?.description !== '') {
            property.description = property.description.replaceAll(
              `@${code}`,
              value,
            )
          }
        })

        if (property?.type !== 'object') {
          property.title = `${tempIndex < 9 ? '0' : ''}${tempIndex + 1}. ${
            property.title
          }`
          tempIndex++
        }
      })
    }
    const visiblePropertyKey = Object.keys(properties)[currentStep]
    setPropertyKey(visiblePropertyKey)
    const updatedSchema = {
      ...schema,
      properties: {
        [visiblePropertyKey]: properties[visiblePropertyKey],
      },
    }

    return updatedSchema
  }

  const isLastStep = currentStep === totalAssessmentCount - 1

  const handleChange = ({ formData }) => {
    setFormData(formData)
    const currentFieldKey = Object.keys(dynamicFormSchema.properties)[0]
    const currentFieldValue: any = Object.values(
      dynamicFormSchema.properties,
    )[0]
    const isRequired = dynamicFormSchema.required?.includes(currentFieldKey)

    if (currentFieldKey in formData) {
      const value = formData[currentFieldKey]
      const detailValue = formData[currentFieldKey + '_detail']

      if (isRequired) {
        if (
          value === '' ||
          value === null ||
          value === undefined ||
          value.length == 0
        ) {
          setIsFieldValid(false)
        } else if (
          currentFieldValue?.properties?.radioOption &&
          (currentFieldValue?.properties?.radioOption?.description ===
            'positive' ||
            currentFieldValue?.properties?.radioOption?.description ===
              'negative')
        ) {
          if (
            currentFieldValue?.properties?.radioOption?.description ===
              'positive' &&
            value === true &&
            (detailValue === '' ||
              detailValue === null ||
              detailValue === undefined)
          ) {
            setIsFieldValid(false)
          } else if (
            currentFieldValue?.properties?.radioOption?.description ===
              'negative' &&
            value === false &&
            (detailValue === '' ||
              detailValue === null ||
              detailValue === undefined)
          ) {
            setIsFieldValid(false)
          } else {
            setIsFieldValid(true)
          }
        } else {
          setIsFieldValid(true)
        }
      } else {
        setIsFieldValid(true)
      }
    } else {
      setIsFieldValid(true)
    }
  }
  const { timeOutside, formatTime } = useOutsideMouseTracker()

  const { mutateAsync: submitAssessment, isLoading: isSavingAssessment } =
    useSubmitAssessment()

  const addTemplatedFormCheck = (jsonData: string) => {
    if (candidateId && assessmentId && assessmentResponseId) {
      submitAssessment({
        candidateId: candidateId,
        assessmentId: assessmentId,
        jsonData,
        assessmentResponseId: assessmentResponseId,
        examCompletionTime: parseFloat(formattedTime?.replace(/:/g, '.') ?? 0),
        mouseOutOfScreen: parseFloat(
          formatTime(timeOutside)?.replace(/:/g, '.') ?? 0,
        ),
      }).then(() => {
        exitFullscreen()
        stopWebcam()
        navigate(
          `/candidate/${candidateId}/assessment/${assessmentId}/thankyou`,
          {
            state: {
              title: 'Thank you!',
              description: 'You have now completed the assessment',
              hasBottomCards: true,
            },
          },
        )
        window.location.reload()
      })
    }
  }

  useEffect(() => {
    if (isSavingAssessment && timerRef.current) {
      timerRef.current.handleStop() // Trigger handleCancel on the Timer component
    }
  }, [isSavingAssessment])

  return (
    <>
      <div
        className={`${className} min-h-screen flex flex-col overflow-y-hidden relative`}>
        {isLoadingDynamicForm && <PageLoader size='xxs' />}
        <div
          ref={disableCopyPasteRef}
          className='flex-grow mx-auto w-[80%] my-8'>
          <div className='w-full flex gap-3'>
            <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
            <Timer
              ref={timerRef}
              duration={parseInt(initialAssessment?.duration ?? '0') * 60}
              allowedImages={maxSnaps}
              onCapture={captureImage}
              setFormattedTime={setFormattedTime}
              addTemplatedFormCheck={addTemplatedFormCheck}
              formData={formData}
              isSavingAssessment={isSavingAssessment}
              handleAssessmentDecline={handleAssessmentDecline}
              isLoadingDeclineAssessment={isLoadingDeclineAssessment}
            />
          </div>
          <div className='flex items-center justify-center'>
            {dynamicForm?.secondaryUrl && (
              <img
                className='w-[130px] h-[42px]'
                src={dynamicForm?.secondaryUrl}
                alt='agency logo'
              />
            )}
          </div>

          {dynamicFormSchema && dynamicForm && (
            <div
              ref={componentRef}
              className='p-4 my-5 bg-white rounded-lg max-h-[70vh] overflow-y-auto styled-scroll'>
              <FormBuilder
                key={formKey}
                ref={dynamicFormRef}
                uiSchema={JSON.parse(dynamicForm.jsonUiSchema)}
                schema={modifyParagraphOrHeadingSchema(dynamicFormSchema)}
                formData={formData}
                onChange={handleChange}
                onError={(data) => {
                  if (data.length > 0) {
                    Toast({
                      alertHeader: 'One or more fields are required.',
                      status: 'Warning',
                    })
                  }
                }}
                onSubmit={(data) => {
                  addTemplatedFormCheck(JSON.stringify(data?.formData))
                }}
              />
            </div>
          )}
        </div>
        {dynamicFormSchema && dynamicForm && (
          <div className='fixed bottom-0 left-0 bg-white w-full flex items-center justify-between px-10 py-2'>
            <div className='flex-col justify-center items-center p-4 pl-[10%]'>
              <span className='text-[#6B7280] text-extra-small font-light'>
                Powered by
              </span>
              <img className='p-[6px]' src={RefHubLogo} alt='Logo' />
            </div>
            <div className='flex h-10 gap-2'>
              {!isLastStep && (
                <Button
                  onClick={() => setCurrentStep((prevStep) => prevStep + 1)}
                  isDisabled={!isFieldValid}>
                  Next
                </Button>
              )}
              {isLastStep && (
                <Button
                  onClick={() => dynamicFormRef.current?.submit()}
                  isDisabled={isSavingAssessment || !isFieldValid}
                  isLoading={isSavingAssessment}>
                  Submit Answers
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      {showFullscreenWarningModal && (
        <ExitFullScreenModal
          showModal={showFullscreenWarningModal}
          secondaryAction={() => {
            addTemplatedFormCheck(JSON.stringify(formData))
          }}
          primaryAction={enterFullscreen}
          closeButtonAction={enterFullscreen}
          secondaryBtnName='Leave full screen'
          primaryBtnName='Stay on full screen'
          isSavingAssessment={isSavingAssessment}
        />
      )}
    </>
  )
}

export default Questions
