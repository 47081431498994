import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import JSONForm from '@rjsf/core'
import { useGetMappedVariables } from 'api'
import CN from 'classnames'
import {
  useAddRefCheck,
  useGetDynamicForm,
  useGetRefereeDetailsById,
} from 'framework/api/methods'
import { ReferenceCheckType } from 'types'

import { LinearStepper, PageLoader, TitleHeader } from 'components/atoms'
import { FormBuilder } from 'components/molecules'

type QuestionnaireProps = {
  [x: string]: any
}

export const Questionnaire = ({
  className,
  ...restProps
}: QuestionnaireProps) => {
  const currentStep = 2
  const steps = [
    { number: 1, label: 'Confirm Employment Dates' },
    { number: 2, label: 'Reference Survey' },
  ]

  const { refereeDetails } = useSelector((state: any) => state.candidate)

  const [dynamicFieldMapping, setDynamicFieldMapping] = useState<Record<
    string,
    string
  > | null>(null)

  useGetMappedVariables(
    { candidateId: refereeDetails.candidateId, refereeId: refereeDetails.id },
    (data) => {
      setDynamicFieldMapping(data)
    },
  )

  const { data: refereeDetailsData } = useGetRefereeDetailsById(
    refereeDetails.candidateId,
    refereeDetails.id,
  )

  const { referenceType } = refereeDetailsData?.referee || {}

  const QuestionnaireClasses = CN(`questionnaire`, className)
  const componentRef = useRef<HTMLDivElement>(null)

  const dynamicFormRef = useRef<JSONForm<any, any> | null>(null)
  const navigate = useNavigate()

  // Adding web ref check
  const { mutateAsync: addRefCheck, isLoading: isSavingRefCheck } =
    useAddRefCheck()

  // Adding web ref check
  const addTemplatedFormCheck = (jsonData: string) => {
    addRefCheck({
      refereeId: location.pathname.split('/')[4],
      htmlResponse: '',
      responseType: 'WEB_CHECK',
      templateId: sessionStorage.getItem('templateId'),
      jsonData,
    }).then(() => {
      navigate(
        `/candidate/${location.pathname.split('/')[2]}/referee/${
          location.pathname.split('/')[4]
        }/thankyou`,
        {
          state: {
            title: 'Thank you!',
            description: 'We will get back to you shortly',
            hasBottomCards: true,
          },
        },
      )
    })
  }

  // Fetching dynamic data for the template
  const { data: dynamicForm, isLoading: isLoadingDynamicForm } =
    useGetDynamicForm(sessionStorage.getItem('templateId') ?? '')

  useEffect(() => {
    const handleTextareaInput = (event: Event) => {
      const textarea = event.target as HTMLTextAreaElement
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }

    const textareas = componentRef.current
      ?.querySelector('form.jason-theme')
      ?.querySelectorAll('textarea')

    if (textareas) {
      textareas.forEach((textarea) => {
        if (textarea instanceof HTMLTextAreaElement) {
          textarea.rows = 5
          textarea.addEventListener('input', handleTextareaInput)
        }
      })
    }

    return () => {
      if (textareas) {
        textareas.forEach((textarea) => {
          if (textarea instanceof HTMLTextAreaElement) {
            textarea.removeEventListener('input', handleTextareaInput)
          }
        })
      }
    }
  }, [dynamicForm])

  const changeJsonSchema = (schema: any) => {
    const properties = { ...schema.properties }

    let tempIndex = 0

    if (dynamicFieldMapping) {
      Object.keys(properties).map((key: string) => {
        const property = properties[key]

        // map dynamic fields
        Object.entries(dynamicFieldMapping).forEach(([code, value]) => {
          if (property?.title !== '') {
            property.title = property.title.replaceAll(`@${code}`, value)
          }

          if (property?.title === '' && property?.description !== '') {
            property.description = property.description.replaceAll(
              `@${code}`,
              value,
            )
          }
        })

        // map question numbers
        if (property?.type !== 'object') {
          property.title = `${tempIndex < 9 ? '0' : ''}${tempIndex + 1}. ${
            property.title
          }`
          tempIndex++
        }
      })
    }

    return {
      ...schema,
      properties,
    }
  }

  return (
    <div className={QuestionnaireClasses} {...restProps}>
      {isLoadingDynamicForm && <PageLoader size='xxs' />}

      {/* header section */}
      <TitleHeader
        title='Questionnaire'
        subTitle='Please express your honest ideas when you fill out this form to have a better evaluation of the candidate.'
      />

      {/* stepper section */}
      {referenceType?.type == ReferenceCheckType?.Employment && (
        <div className='flex p-5 mt-5 bg-white w-full justify-center'>
          <LinearStepper steps={steps} currentStep={currentStep} />
        </div>
      )}

      {dynamicForm && (
        <div ref={componentRef} className='p-4 mt-5 bg-white rounded-lg'>
          <FormBuilder
            className={`${
              referenceType?.type == ReferenceCheckType?.Employment
                ? 'formBuilderHeightWithEmployment'
                : 'formBuilderHeight'
            } overflow-y-scroll`}
            ref={dynamicFormRef}
            uiSchema={JSON.parse(dynamicForm.jsonUiSchema)}
            schema={changeJsonSchema(JSON.parse(dynamicForm.jsonSchema))}
            onError={(data) => {
              if (data.length > 0) {
                Toast({
                  alertHeader: 'One or more field is required.',
                  status: 'Warning',
                })
              }
            }}
            onSubmit={(data) => {
              addTemplatedFormCheck(JSON.stringify(data?.formData))
            }}
          />
        </div>
      )}
      <div className='p-4 mt-5 bg-Cobalt-50 rounded-lg h-[88px] flex items-center justify-end'>
        <div className='flex gap-2'>
          <Button
            onClick={() => {
              dynamicFormRef.current?.submit()
            }}
            isLoading={isSavingRefCheck}>
            Submit Answers
          </Button>
          <Button
            theme='white'
            textTheme='blue'
            onClick={() => {
              navigate(-1)
              const timeout = setTimeout(() => {
                window.location.reload()
              }, 200)
              return () => clearTimeout(timeout)
            }}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  )
}
