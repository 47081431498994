import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

type VerifySurveyProps = {
  candidateId: string
  SurveyResponseID?: string | null
}

export const useVerifySurvey = () => {
  const mutate = useMutation(
    ({ candidateId, SurveyResponseID }: VerifySurveyProps) => {
      return get(
        `Candidate/${candidateId}/Surveys/${SurveyResponseID}/VerifySurvey`,
      )
    },
  )

  return mutate
}
