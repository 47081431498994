type ProgressBar = {
  currentStep: number
  totalSteps: number
}

const ProgressBar = ({ currentStep, totalSteps }: ProgressBar) => {
  const progressPercentage = (currentStep / totalSteps) * 100

  return (
    <div className='flex justify-between items-center gap-3 w-4/5 my-5 bg-white rounded-lg p-4'>
      <span className='text-sm font-medium text-gray-700'>{`${currentStep}/${totalSteps}`}</span>
      <div className='w-full bg-Gray-200 rounded-full h-2 '>
        <div
          className='bg-[#085DA8] h-2 rounded-full'
          style={{ width: `${progressPercentage}%` }}></div>
      </div>
    </div>
  )
}

export default ProgressBar
