import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type CreateFeedbackRequest = {
  candidateId: string
  refereeId?: string
  type: string
  rating: number
  comments: string
}

export const useCreateFeedback = () => {
  const mutate = useMutation((request: CreateFeedbackRequest) => {
    return postAPI('/feedback', request)
  })

  return mutate
}
