import { useCallback, useEffect, useState } from 'react'

export const useWebcamPermission = () => {
  const [hasWebcamPermission, setHasWebcamPermission] = useState(false)
  //   const [isWebcamOn, setIsWebcamOn] = useState(false)
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)

  const checkWebcamPermission = useCallback(async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      })

      const permissionGranted = permissionStatus.state === 'granted'
      setHasWebcamPermission(permissionGranted)

      // Do not start the webcam here
      //   if (permissionGranted) {
      //     try {
      //       // Attempt to get a media stream to check if the webcam is already on
      //       const stream = await navigator.mediaDevices.getUserMedia({
      //         video: true,
      //       })
      //       setMediaStream(stream)
      //       setIsWebcamOn(stream.active)
      //     } catch (err) {
      //       setIsWebcamOn(false)
      //     }
      //   } else {
      //     setIsWebcamOn(false)
      //   }
    } catch (err) {
      console.error('Error checking webcam permission:', err)
      setHasWebcamPermission(false)
      //   setIsWebcamOn(false)
    }
  }, [])

  //   const startWebcam = async () => {
  //     try {
  //       const stream = await navigator.mediaDevices.getUserMedia({
  //         video: true,
  //       })
  //       setMediaStream(stream)
  //       setIsWebcamOn(true)
  //     } catch (err) {
  //       console.error('Error accessing webcam:', err)
  //       setIsWebcamOn(false)
  //     }
  //   }

  // const stopWebcam = () => {
  //   if (mediaStream) {
  //     mediaStream.getTracks().forEach((track) => track.stop())
  //     //   setIsWebcamOn(false)
  //     setMediaStream(null)
  //   }
  // }

  const stopWebcam = () => {
    if (mediaStream) {
      console.log('Stopping webcam stream:', mediaStream)
      mediaStream.getTracks().forEach((track) => track.stop())
      setMediaStream(null)
    } else {
      console.log('No media stream found')
    }
  }

  useEffect(() => {
    checkWebcamPermission() // Check permission and webcam status on component mount

    // Clean up function to stop webcam when component unmounts
    return () => {
      stopWebcam()
    }
  }, [checkWebcamPermission, hasWebcamPermission])

  return { hasWebcamPermission, /*isWebcamOn, startWebcam,*/ stopWebcam }
}
