import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Modal, TextField } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { format, parse } from 'date-fns'
import {
  AppSettingKey,
  AppSettingProps,
  isSettingEnabled,
  useGetAgencySettingsByTenantId,
} from 'features/settings'
import { Form, Formik, FormikValues } from 'formik'
import { Referee, useAddReferees } from 'framework/api/methods'
import moment from 'moment'
import {
  setCurrentReferee,
  setShowCandidateAvatarError,
  setShowCandidateAvatarErrorPopup,
} from 'store/reducers/candidate/candidateSlice'
import { ReferenceCheckType } from 'types/referenceCheckType'
import * as Yup from 'yup'

import { CalendarInput, PhoneInputField } from 'components/atoms'

type AddRefereesDetailsCardProps = {
  maxReferees: number
  setIsCompleted: (value: boolean) => void
  setShowRefereeFeedbackModalOpen: (value: boolean) => void
  [x: string]: any
}

export const AddRefereesDetailsCard = ({
  maxReferees,
  setIsCompleted,
  setShowRefereeFeedbackModalOpen,
  className,
  ...restProps
}: AddRefereesDetailsCardProps) => {
  const [agencySettings, setAgencySettings] = useState<AppSettingProps[]>([])
  const [refereeTypes, setRefereeTypes] = useState<any>(null)
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState<boolean>(false)

  const AddRefereesDetailsCardClasses = CN(
    `add-referees-details-card bg-white rounded-lg p-4 mt-3 flex flex-col relative`,
    className,
  )

  const {
    currentReferee,
    candidateProfile,
    showCandidateAvatarErrorPopup,
    agencyData,
  } = useSelector((state: any) => state.candidate)

  const dispatch = useDispatch()

  useEffect(() => {
    if (candidateProfile?.referenceTypes) {
      const notSubmittedReferees = candidateProfile?.referenceTypes?.filter(
        (ref) => ref.isSubmitted === false,
      )

      setRefereeTypes(notSubmittedReferees)
    }
  }, [candidateProfile?.referenceTypes])

  /** API call for getting agency profile settings */
  const { refetch } = useGetAgencySettingsByTenantId(
    agencyData.id,
    (appSettings) => {
      setAgencySettings(appSettings)
    },
  )

  useEffect(() => {
    if (agencySettings.length == 0) {
      refetch()
    }
  }, [agencyData])

  const isProfileImgRequired = isSettingEnabled(
    AppSettingKey.IsCandidateProfileImageRequired,
    agencySettings,
  )
  const isRefereeEmailRequired = isSettingEnabled(
    AppSettingKey.IsRefereeEmailRequired,
    agencySettings,
  )

  const AddRefereeSchemaForEmployment = Yup.object().shape({
    firstName: Yup.string()
      .required('Required')
      .matches(/^[a-zA-Z\s]+$/, 'Invalid name format'),
    lastName: Yup.string()
      .required('Required')
      .matches(/^[a-zA-Z\s]+$/, 'Invalid name format'),
    phone: Yup.string()
      .required('Required')
      .matches(/^(?:\S+\s+){1}(\S+\s+)*(\D*\d){8}/, 'Invalid phone number'),
    countryCode: Yup.string(),
    email: isRefereeEmailRequired
      ? Yup.string()
          .email('Invalid email')
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Invalid email format',
          )
          .required('Required')
      : Yup.string()
          .email('Invalid email')
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Invalid email format',
          )
          .optional(),
    candidateCompany: Yup.string().required('Required'),
    candidatePosition: Yup.string().required('Required'),
    candidateRelation: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    jobTitle: Yup.string().required('Required'),
    startDate: Yup.date().required('Required'),
    endDate: isCurrentlyWorking
      ? Yup.date().optional()
      : Yup.date()
          .required('Required')
          .when('startDate', {
            is: (startDate: any) => startDate && startDate !== '',
            then: Yup.date()
              .min(
                Yup.ref('startDate'),
                ({ min }) =>
                  `End Date needs to be after ${moment(min).format(
                    'DD/MM/YYYY',
                  )}!`,
              )
              .required('Required'),
          }),
    isCurrentlyWorking: Yup.boolean().default(false),
  })

  const AddRefereeSchemaForProfessional = Yup.object().shape({
    firstName: Yup.string()
      .required('Required')
      .matches(/^[a-zA-Z\s]+$/, 'Invalid name format'),
    lastName: Yup.string()
      .required('Required')
      .matches(/^[a-zA-Z\s]+$/, 'Invalid name format'),
    phone: Yup.string()
      .required('Required')
      .matches(/^(?:\S+\s+){1}(\S+\s+)*(\D*\d){8}/, 'Invalid phone number'),
    countryCode: Yup.string(),
    email: isRefereeEmailRequired
      ? Yup.string()
          .email('Invalid email')
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Invalid email format',
          )
          .required('Required')
      : Yup.string()
          .email('Invalid email')
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Invalid email format',
          )
          .optional(),
    candidateRelation: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    jobTitle: Yup.string().required('Required'),
  })

  const AddRefereeSchemaForFriendFamily = Yup.object().shape({
    firstName: Yup.string()
      .required('Required')
      .matches(/^[a-zA-Z\s]+$/, 'Invalid name format'),
    lastName: Yup.string()
      .required('Required')
      .matches(/^[a-zA-Z\s]+$/, 'Invalid name format'),
    phone: Yup.string()
      .required('Required')
      .matches(/^(?:\S+\s+){1}(\S+\s+)*(\D*\d){8}/, 'Invalid phone number'),
    countryCode: Yup.string(),
    email: isRefereeEmailRequired
      ? Yup.string()
          .email('Invalid email')
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Invalid email format',
          )
          .required('Required')
      : Yup.string()
          .email('Invalid email')
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Invalid email format',
          )
          .optional(),
    candidateRelation: Yup.string().required('Required'),
  })

  /** add referees */
  const { mutateAsync: addReferees, isLoading: addRefereeIsLoading } =
    useAddReferees()

  return (
    <>
      <Formik
        validateOnChange
        initialValues={
          (JSON.parse(
            sessionStorage.getItem('referees') || '[]',
          )[0] as Referee) || {
            firstName: '',
            lastName: '',
            phone: '+61',
            countryCode: 'AU',
            email: '',
            candidateCompany: '',
            candidatePosition: '',
            candidateRelation: '',
            company: '',
            jobTitle: '',
            startDate: '',
            endDate: '',
            referenceType: null,
            isCurrentlyWorking: false,
          }
        }
        onSubmit={(values: FormikValues, { resetForm, setValues }: any) => {
          if (maxReferees === currentReferee && !candidateProfile?.avatarUrl) {
            if (isProfileImgRequired) {
              dispatch(setShowCandidateAvatarError(true))
              dispatch(setShowCandidateAvatarErrorPopup(true))
              return
            }
          }

          dispatch(setShowCandidateAvatarError(false))

          if (sessionStorage.getItem('referees')) {
            const referees = JSON.parse(
              sessionStorage.getItem('referees') || '',
            )
            if (currentReferee === maxReferees) {
              referees[currentReferee - 1] = values
              addReferees({
                candidateId: sessionStorage.getItem('candidateId'),
                referees,
                referenceType: refereeTypes,
              }).then(() => {
                setIsCompleted(true)
                setShowRefereeFeedbackModalOpen(true)
              })
            } else {
              if (referees[currentReferee - 1]) {
                referees[currentReferee - 1] = values
              } else {
                referees.push(values)
              }
              sessionStorage.setItem('referees', JSON.stringify(referees))
              resetForm()
              if (referees[currentReferee]) {
                setValues(referees[currentReferee])
              }
              dispatch(setCurrentReferee(currentReferee + 1))
            }
          } else {
            if (maxReferees === 1) {
              addReferees({
                candidateId: sessionStorage.getItem('candidateId'),
                referees: [values as Referee],
                referenceType: refereeTypes,
              }).then(() => {
                setIsCompleted(true)
                setShowRefereeFeedbackModalOpen(true)
              })
            } else {
              dispatch(setCurrentReferee(currentReferee + 1))
              resetForm()
            }
            sessionStorage.setItem('referees', JSON.stringify([values]))
          }
        }}
        validationSchema={
          refereeTypes
            ? refereeTypes[currentReferee - 1]?.type ===
              ReferenceCheckType?.Employment
              ? AddRefereeSchemaForEmployment
              : refereeTypes[currentReferee - 1]?.type ===
                ReferenceCheckType?.Professional
              ? AddRefereeSchemaForProfessional
              : AddRefereeSchemaForFriendFamily
            : AddRefereeSchemaForFriendFamily
        }>
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setTouched,
          values,
          resetForm,
          setValues,
          validateField,
        }) => (
          <Form>
            <div className={AddRefereesDetailsCardClasses} {...restProps}>
              <span className='mb-3 text-Gray-800 text-heading-4 font-Medium'>
                Referee 0{currentReferee}
              </span>
              <div className='p-4 border rounded-lg shadow-sm border-Gray-100'>
                <span className='text-base text-Gray-800 font-SemiBold'>
                  Referee Details
                </span>
                <div className='flex flex-col mt-5 mb-0 md:flex-row md:mb-2'>
                  <TextField
                    className='flex-1 mb-5 md:mr-5 md:mb-0'
                    label='First Name'
                    value={values.firstName}
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ''
                    }
                    isErrored={errors.firstName && touched.firstName}
                    onChange={handleChange('firstName')}
                    onBlur={handleBlur('firstName')}
                    placeholder='First Name'
                  />
                  <TextField
                    className='flex-1'
                    label='Last Name'
                    value={values.lastName}
                    onChange={handleChange('lastName')}
                    onBlur={handleBlur('lastName')}
                    helperText={
                      errors.lastName && touched.lastName ? errors.lastName : ''
                    }
                    isErrored={errors.lastName && touched.lastName}
                    placeholder='Last Name'
                  />
                </div>
                <div className='flex flex-col mb-0 md:flex-row md:mb-2'>
                  <PhoneInputField
                    label='Phone'
                    value={values.phone}
                    onChange={handleChange('phone')}
                    onChangeCountryCode={(countryCode: string) => {
                      setFieldValue('countryCode', countryCode)
                    }}
                    onBlur={handleBlur('phone')}
                    isErrored={errors.phone && touched.phone ? true : false}
                    helperText={
                      errors.phone && touched.phone ? errors.phone : ''
                    }
                    className='flex-1 mt-5 mb-5 md:mr-5 md:mb-0 md:mt-0'
                  />
                  <TextField
                    className='flex-1'
                    label='Email'
                    value={values.email}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    helperText={
                      errors.email && touched.email ? errors.email : ''
                    }
                    isErrored={errors.email && touched.email}
                    placeholder='Email'
                  />
                </div>
                {refereeTypes &&
                  refereeTypes[currentReferee - 1]?.type !=
                    ReferenceCheckType?.FriendFamily && (
                    <div className='flex flex-col mb-0 md:flex-row md:mb-2'>
                      <TextField
                        className='flex-1 mt-5 mb-5 md:mr-5 md:mb-0 md:mt-0'
                        label='Company Name'
                        value={values.company}
                        onChange={handleChange('company')}
                        onBlur={handleBlur('company')}
                        helperText={
                          errors.company && touched.company
                            ? errors.company
                            : ''
                        }
                        isErrored={errors.company && touched.company}
                        placeholder='Company Name'
                      />
                      <TextField
                        className='flex-1'
                        label="Referee's job title"
                        value={values.jobTitle}
                        onChange={handleChange('jobTitle')}
                        onBlur={handleBlur('jobTitle')}
                        helperText={
                          errors.jobTitle && touched.jobTitle
                            ? errors.jobTitle
                            : ''
                        }
                        isErrored={errors.jobTitle && touched.jobTitle}
                        placeholder="Referee's job title"
                      />
                    </div>
                  )}

                <div className='flex flex-col mb-0 md:flex-row md:mb-2'>
                  <TextField
                    className='flex-1 mt-5 mb-5 md:mr-5 md:mb-0 md:mt-0'
                    label='Relationship to you'
                    value={values.candidateRelation}
                    onChange={handleChange('candidateRelation')}
                    onBlur={handleBlur('candidateRelation')}
                    helperText={
                      errors.candidateRelation && touched.candidateRelation
                        ? errors.candidateRelation
                        : ''
                    }
                    isErrored={
                      errors.candidateRelation && touched.candidateRelation
                    }
                    placeholder='Relationship to you'
                  />
                  <div className='flex-1' />
                </div>
              </div>
              {refereeTypes &&
                refereeTypes[currentReferee - 1]?.type ==
                  ReferenceCheckType?.Employment && (
                  <div className='p-4 mt-4 border rounded-lg shadow-sm border-Gray-100'>
                    <span className='text-base text-Gray-800 font-SemiBold'>
                      Details of the role you held at this company
                    </span>

                    <div className='flex flex-col mt-5 mb-0 md:flex-row md:mb-2'>
                      <TextField
                        className='flex-1 mb-5 md:mr-5 md:mb-0'
                        label='Company Name'
                        value={values.candidateCompany}
                        onChange={handleChange('candidateCompany')}
                        onBlur={handleBlur('candidateCompany')}
                        helperText={
                          errors.candidateCompany && touched.candidateCompany
                            ? errors.candidateCompany
                            : ''
                        }
                        isErrored={
                          errors.candidateCompany && touched.candidateCompany
                        }
                        placeholder='Company Name'
                      />
                      <TextField
                        className='flex-1'
                        label='Position held at this company'
                        value={values.candidatePosition}
                        onChange={handleChange('candidatePosition')}
                        onBlur={handleBlur('candidatePosition')}
                        helperText={
                          errors.candidatePosition && touched.candidatePosition
                            ? errors.candidatePosition
                            : ''
                        }
                        isErrored={
                          errors.candidatePosition && touched.candidatePosition
                        }
                        placeholder='Position held at this company'
                      />
                    </div>

                    <div className='flex flex-col mb-0 md:flex-row md:mb-2'>
                      <div className='flex-1 mt-5 mb-5 md:mr-5 md:mb-0 md:mt-0'>
                        <span className='text-small text-Gray-800 font-Medium'>
                          Start Date
                        </span>
                        <CalendarInput
                          value={values.startDate}
                          formatDate={(date) => format(date, 'dd/MM/yyyy')}
                          parseDate={(str) =>
                            parse(str, 'dd/MM/yyyy', new Date())
                          }
                          disableFutureDates={true}
                          isErrored={
                            errors.startDate && touched.startDate ? true : false
                          }
                          onChange={(date) => {
                            setTouched({ ...touched, startDate: true })
                            setFieldValue('startDate', date)
                            validateField('startDate')
                          }}
                          helperText={
                            errors.startDate && touched.startDate
                              ? errors.startDate
                              : ''
                          }
                        />
                      </div>

                      <div className='flex-1 mb-5 md:mb-0'>
                        <span className='text-small text-Gray-800 font-Medium'>
                          End Date
                        </span>
                        <CalendarInput
                          value={values.endDate}
                          formatDate={(date) => format(date, 'dd/MM/yyyy')}
                          parseDate={(str) =>
                            parse(str, 'dd/MM/yyyy', new Date())
                          }
                          disabled={values.isCurrentlyWorking}
                          onChange={(date) => {
                            setTouched({ ...touched, endDate: true })
                            setFieldValue('endDate', date)
                            validateField('endDate')
                          }}
                          isErrored={
                            errors.endDate && touched.endDate ? true : false
                          }
                          helperText={
                            errors.endDate && touched.endDate
                              ? errors.endDate
                              : ''
                          }
                        />
                      </div>
                    </div>

                    <div className='flex flex-col mb-0 md:flex-row'>
                      <Checkbox
                        labelText='I am currently working in this role'
                        className='mt-2'
                        labelTextClassName='font-Regular'
                        checkboxClassName='mt-0.5'
                        checked={values.isCurrentlyWorking}
                        onChange={(e: any) => {
                          setFieldValue('isCurrentlyWorking', e.target.checked)
                          setIsCurrentlyWorking(e.target.checked)
                        }}
                      />
                    </div>
                  </div>
                )}
            </div>
            <div
              className={CN('mt-3 flex flex-col sm:flex-row', {
                'justify-end': currentReferee === 1,
                'justify-between': currentReferee > 1,
              })}>
              {currentReferee > 1 && (
                <Button
                  className='mb-2 sm:mb-0'
                  type='button'
                  onClick={() => {
                    resetForm()
                    const referees = JSON.parse(
                      sessionStorage.getItem('referees') || '',
                    )
                    if (currentReferee === maxReferees) {
                      referees[currentReferee - 1] = values
                      sessionStorage.setItem(
                        'referees',
                        JSON.stringify(referees),
                      )
                    }
                    setValues(referees[currentReferee - 2])
                    dispatch(setCurrentReferee(currentReferee - 1))
                  }}
                  textTheme='blue'
                  theme='white'
                  size='xl'>
                  Back to Referee{' '}
                  {currentReferee - 1 <= 9
                    ? `0${currentReferee - 1}`
                    : currentReferee - 1}
                </Button>
              )}
              <Button
                isDisabled={addRefereeIsLoading}
                type='submit'
                className='group min-w-[200px]'
                size='xl'>
                {currentReferee === maxReferees
                  ? 'Save Referees'
                  : ` Go to Referee ${
                      currentReferee + 1 <= 9
                        ? `0${currentReferee + 1}`
                        : currentReferee + 1
                    }`}
                <i className='ri-arrow-right-line text-[14px] leading-none text-white ml-2 hidden group-hover:block' />
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <Modal
        isActive={showCandidateAvatarErrorPopup}
        onClickSecondaryBtn={() => {
          dispatch(setShowCandidateAvatarErrorPopup(false))
        }}
        isHeaderShow={false}
        modalProps={{
          className: 'w-[400px] md:w-[466px] bg-white',
        }}
        footerButtonAlignment='center'
        primaryButtonTitle=''
        secondaryButtonProps={{
          textTheme: 'black',
          className: 'w-2/5',
        }}
        secondaryButtonTitle='Okay'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-50'>
            <i className='ri-error-warning-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Profile Picture is Missing
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Please upload a profile picture to continue
          </span>
        </div>
      </Modal>
    </>
  )
}
