import React, { FC } from 'react'
import CN from 'classnames'

export interface SuccessIconProps {
  className?: string | undefined
  isCustomStyles?: boolean
  [x: string]: any
}

export const SuccessIcon: FC<SuccessIconProps> = ({
  className,
  isCustomStyles,
  ...restProps
}: SuccessIconProps) => {
  /** button main styles*/
  const successIconClassName = !isCustomStyles
    ? CN(
        'flex justify-center items-center w-12 h-12 rounded-full bg-Green-300 border-[6px] border-Green-100 hover:bg-Green-400 hover:border-Green-200',
        className,
      )
    : className

  return (
    <div className={successIconClassName} {...restProps}>
      <i className='ri-check-fill text-white text-[22px]'></i>
    </div>
  )
}

SuccessIcon.defaultProps = {}

export default SuccessIcon
