/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSubmittedClicked: false,
  currentReferee: 1,
  candidateProfile: {},
  agencyData: {},
  refereeDetails: {},
  showCandidateAvatarError: false,
  showCandidateAvatarErrorPopup: false,
}

const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    setIsSubmittedClickedState(state, { payload }) {
      state.isSubmittedClicked = payload
    },

    setCurrentRefereeState(state, { payload }) {
      state.currentReferee = payload
    },

    setCandidateProfileState(state, { payload }) {
      state.candidateProfile = payload
    },

    setAgencyDataState(state, { payload }) {
      state.agencyData = payload
    },

    setRefereeDetailsState(state, { payload }) {
      state.refereeDetails = payload
    },

    setShowCandidateAvatarErrorState(state, { payload }) {
      state.showCandidateAvatarError = payload
    },

    setShowCandidateAvatarErrorPopupState(state, { payload }) {
      state.showCandidateAvatarErrorPopup = payload
    },
  },
})

export const {
  setIsSubmittedClickedState,
  setCurrentRefereeState,
  setCandidateProfileState,
  setAgencyDataState,
  setRefereeDetailsState,
  setShowCandidateAvatarErrorState,
  setShowCandidateAvatarErrorPopupState,
} = candidateSlice.actions

export const setIsSubmittedClicked =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsSubmittedClickedState(payload))
  }

export const setCurrentReferee =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setCurrentRefereeState(payload))
  }

export const setCandidateProfile =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setCandidateProfileState(payload))
  }

export const setAgencyData =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setAgencyDataState(payload))
  }

export const setRefereeDetails =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setRefereeDetailsState(payload))
  }

export const setShowCandidateAvatarError =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setShowCandidateAvatarErrorState(payload))
  }

export const setShowCandidateAvatarErrorPopup =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setShowCandidateAvatarErrorPopupState(payload))
  }

export default candidateSlice.reducer
