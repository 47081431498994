import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

type VerifyAssessmentProps = {
  candidateId: string
  AssessmentResponseID?: string | null
}

export const useVerifyAssessment = () => {
  const mutate = useMutation(
    ({ candidateId, AssessmentResponseID }: VerifyAssessmentProps) => {
      return get(
        `assessment/candidate/${candidateId}/Assessment/${AssessmentResponseID}/VerifyAssessment`,
      )
    },
  )

  return mutate
}
