import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { putAPI } from 'framework/api/http'

export const useUpdateCorrectInformation = (
  candidateId: string,
  refereeId: string,
) => {
  return useMutation(
    ({
      candidateRelation,
      endDate,
      jobTitle,
      startDate,
      isCurrentlyWorking,
    }: {
      jobTitle: string
      candidateRelation: string
      startDate: string
      endDate: string
      isCurrentlyWorking: boolean
    }) => {
      return putAPI(
        `/Candidates/${candidateId}/Referees/${refereeId}/correct-info`,
        {
          jobTitle,
          candidateRelation,
          startDate,
          endDate,
          isCurrentlyWorking,
        },
        {},
      )
    },
    {
      onError: () => {
        Toast({
          alertHeader: `Failed to submit your response`,
          status: 'Error',
        })
      },
    },
  )
}
