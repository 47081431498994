import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { postAPI } from 'framework/api/http'

type DeclineCandidateAssessmentRequest = {
  candidateId: string
  assessmentId: string
  assessmentResponseId: string
}

export const useDeclineCandidateAssessment = () => {
  return useMutation(
    ({
      candidateId,
      assessmentId,
      assessmentResponseId,
    }: DeclineCandidateAssessmentRequest) => {
      return postAPI(
        `/assessment/candidates/${candidateId}/assessment/${assessmentId}/Decline`,
        {
          assessmentResponseId,
        },
      )
    },
    {
      onError: () => {
        Toast({
          alertHeader: `Error declining survey`,
          status: 'Error',
        })
      },
    },
  )
}
