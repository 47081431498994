import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Toast } from '@labourhub/labour-hub-ds'
import { useCreateFeedback } from 'api/feedback'
import Feedback from 'assets/images/Feedback.png'
import { ModalProps } from 'types'

import { PageLoader, Rate } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { H3 } from 'components/atoms/Headings'
import { RowWithAlignment } from 'components/atoms/Row'

type RefereeFeedbackSurveyModalProps = ModalProps & {
  feedbackType: string
}

type URLParams = {
  cid: string
  rid?: string
}

export const RefereeFeedbackSurveyModal = ({
  isModalActive,
  setIsModalActive,
  feedbackType,
}: RefereeFeedbackSurveyModalProps) => {
  const { cid } = useParams<URLParams>()
  const { rid } = useParams<URLParams>()
  const [rating, setRating] = useState(5)
  const [comments, setComments] = useState('')
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  useEffect(() => {
    setIsSubmitDisabled(rating === 0 || comments === '')
  }, [rating, comments])

  const { mutate, isLoading } = useCreateFeedback()
  const handleSendFeedback = () => {
    if (cid) {
      mutate(
        {
          candidateId: cid,
          refereeId: rid,
          type: feedbackType,
          rating: rating,
          comments: comments,
        },
        {
          onSuccess: ({ data: successData }: any) => {
            Toast({
              alertHeader: successData?.message,
              alertBody: '',
              status: 'Success',
            })
            setIsModalActive(false)
          },
          onError: ({ response: { data: errData } }: any) => {
            Toast({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
          },
        },
      )
    }
  }

  const resetFields = () => {
    setRating(0)
    setComments('')
  }

  const handleClose = () => {
    setIsModalActive(false)
    resetFields()
  }

  return (
    <Modal
      isActive={isModalActive}
      headerTitle=''
      className='z-50'
      onHeaderCloseButtonClick={() => handleClose()}
      onClickSecondaryBtn={() => handleClose()}
      onOverlayClick={() => handleClose()}
      onClickPrimaryBtn={() => handleSendFeedback()}
      isSeparator={false}
      modalProps={{
        style: { width: '574px' },
      }}
      headerCloseButtonProps={{
        style: { fontSize: '24px' },
      }}
      primaryButtonTitle='Send Feedback'
      secondaryButtonTitle='Not Now'
      footerProps={{
        style: {
          justifyContent: 'flex-start',
          flexDirection: 'row-reverse',
        },
      }}
      primaryButtonProps={{
        style: { marginLeft: '12px' },
        isDisabled: isSubmitDisabled,
      }}
      secondaryButtonProps={{ textTheme: 'black' }}>
      {isLoading && <PageLoader size='xxs' />}

      {/* Content of RefereeFeedbackSurvey*/}
      <RowWithAlignment
        justifyAlignment='center'
        className='flex flex-col pb-10'>
        <Column className='text-center'>
          <H3 title='We Value Your Feedback!' />
          <span className='text-base'>
            Leave us feedback so we know how we’re doing.
          </span>
          <img src={Feedback} alt={'Feedback'} className='w-[168px] mx-auto' />

          <Column className='mx-3 mt-6 space-y-3 text-base'>
            <span className='font-Bold'>Rate your Ref Hub experience</span>
            <Rate
              rating={rating}
              onRating={(rate) => setRating(rate)}
              count={5}
              defaultRating={5}
            />

            <textarea
              value={comments}
              onChange={(e: any) => setComments(e.target.value)}
              rows={5}
              placeholder='Tell us what you think...'
              className='w-[465px] resize-none styled-scroll p-2 text-Gray-800 rounded-md border  border-Gray-300 shadow-sm focus:outline-Cobalt-400'
            />
          </Column>
        </Column>
      </RowWithAlignment>
    </Modal>
  )
}
