import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface GetAgencyBySubDomainResponse {
  message: string
  messageTitle: string | null
  agencyProfile: AgencyProfile
}

export interface Address {
  lineOne: string
  lineTwo: string
  city: string
  suburb: string
  state: string
  stateCode: string
  country: string
  countryCode: string
  postCode: string
  latitude: number
  longitude: number
}

export interface AgencyProfile {
  id: string
  createdOn: string
  updatedOn: string
  name: string
  email: string
  logoUrl: string
  secondaryLogoUrl: string
  abn: string
  industry: string
  size: string
  website: string
  subdomain: string
  address: Address
  phone: string
  about: string
  referedBy: string
}

export const useGetAgencyBySubDomain = (
  subDomain: string | null,
  onSuccess: (data: GetAgencyBySubDomainResponse) => void,
) => {
  return useQuery(
    ['GET_AGENCY_BY_SUB_DOMAIN', subDomain],
    async () => {
      const data = await get(`AgencyProfile/SubDomain?subDomain=${subDomain}`)
      return (data?.data as GetAgencyBySubDomainResponse) || []
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching agency profile',
          status: 'Error',
        })
      },
      onSuccess,
    },
  )
}
