/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useMemo, useState } from 'react'
import CN from 'classnames'

export interface RateProps {
  count?: number | undefined
  className?: string | undefined
  rating?: number
  onChange?: any
  defaultRating?: number
  [x: string]: any
}

export const Rate: FC<RateProps> = ({
  count = 5,
  rating = 0,
  onRating,
  className,
  defaultRating,
  ...restProps
}: RateProps) => {
  const RateClasses = CN('rate form-rating', className)

  const [hoverRating, setHoverRating] = useState<number>(0)
  const [clickedRate, setClickedRate] = useState<number>(rating)

  useEffect(() => {
    if (defaultRating !== undefined) {
      setClickedRate(defaultRating)
      setHoverRating(defaultRating)
    }
  }, [defaultRating])

  const starRating = useMemo(() => {
    return Array.from({ length: count }, (_, i) => i + 1).map((idx) => (
      <i
        key={idx}
        className={CN('cursor-pointer text-[24px] pr-3', {
          'ri-star-fill text-Yellow-300':
            hoverRating >= idx || clickedRate >= idx,
          'ri-star-line text-Gray-400': hoverRating < idx && clickedRate < idx,
        })}
        onClick={() => {
          setClickedRate(idx)
          if (onRating) onRating(idx)
        }}
        onMouseEnter={() => setHoverRating(idx)}
        onMouseLeave={() => setHoverRating(0)}
      />
    ))
  }, [count, hoverRating, clickedRate, onRating])

  return (
    <div className={RateClasses} {...restProps}>
      {starRating}
    </div>
  )
}

export default Rate
