import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface GetRefereeByIdResponse {
  message: string
  messageTitle: string | null
  referee: refereeProps
}

export interface refereeProps {
  id: string
  candidateId: string
  firstName: string
  lastName: string
  phone: string
  email: string
  company: string
  jobTitle: string
  candidateCompany: string
  candidateRelation: string
  candidatePosition: string
  startDate: string
  endDate: string
  templateId: string
  referenceType?: {
    note: string
    specificEmployer: string
    type: string
  }
  isCurrentlyWorking?: boolean
}

export const useGetRefereeDetailsById = (
  candidateId: string | null,
  refereeId: string | null,
) => {
  return useQuery(
    ['GET_REFEREE_BY_ID', candidateId],
    async () => {
      if (candidateId !== '') {
        const data = await get(`Candidate/${candidateId}/Referee/${refereeId}`)
        return (data?.data as GetRefereeByIdResponse) || []
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching candidate details.',
          status: 'Error',
        })
      },
      onSuccess: (data) => {
        sessionStorage.setItem('templateId', data?.referee?.templateId || '')
      },
    },
  )
}
