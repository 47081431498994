import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Modal, TextField } from '@labourhub/labour-hub-ds'
import profileLogo from 'assets/images/Profile-Icon.svg'
import CN from 'classnames'
import { format, parse } from 'date-fns'
import { Formik } from 'formik'
import {
  useDeclineRefCheck,
  useGetCandidateById,
  useGetRefereeDetailsById,
  useUpdateCorrectInformation,
} from 'framework/api/methods'
import moment from 'moment'
import { setRefereeDetails } from 'store/reducers/candidate/candidateSlice'
import {
  setIsCareerInterest,
  setIsStaffRequest,
} from 'store/reducers/layout/layoutSlice'
import { ReferenceCheckType } from 'types'
import { boolean, date, object, ref, string } from 'yup'

import {
  CalendarInput,
  LinearStepper,
  PageLoader,
  TitleHeader,
} from 'components/atoms'
import { capitalizeFirstLetter } from 'utils'

type CandidateDetailsProps = {
  imageSource?: string | undefined
  [x: string]: any
}

export const CandidateDetails = ({
  className,
  ...restProps
}: CandidateDetailsProps) => {
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)
  const { candidateProfile, agencyData } = useSelector(
    (state: any) => state.candidate,
  )

  const [isCurrentlyWorking] = useState<boolean>(false)

  const incorrectInformationSchema = object({
    jobTitle: string().required(),
    employmentStartDate: date()
      .required()
      .typeError('Invalid Employment Start Date.')
      .max(
        ref('employmentEndDate'),
        ({ max }) =>
          `Date needs to be before ${moment(max).format('DD/MM/YYYY')}!`,
      ),
    employmentEndDate: isCurrentlyWorking
      ? date().optional()
      : date()
          .required()
          .typeError('Invalid Employment End Date.')
          .min(
            ref('employmentStartDate'),
            ({ min }) =>
              `Date needs to be after ${moment(min).format('DD/MM/YYYY')}!`,
          ),
    relationShip: string().required(),
    isCurrentlyWorking: boolean().default(false),
  })

  const { pathname: locationPath } = useLocation()
  const candidateId = locationPath.split('/')[2]
  const refereeId = locationPath.split('/')[4]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = sessionStorage.getItem('token')

  const currentStep = 1
  const steps = [
    { number: 1, label: 'Confirm Employment Dates' },
    { number: 2, label: 'Reference Survey' },
  ]

  const {
    refetch: getRefereeDetails,
    data: refereeDetails,
    isLoading: loadingRefereeDetailsById,
  } = useGetRefereeDetailsById(candidateId, refereeId)

  const {
    mutateAsync: correctIncorrectInformation,
    isLoading: isUpdatingIncorrectInformation,
  } = useUpdateCorrectInformation(candidateId, refereeId)

  const { isLoading: isLoadingCandidateInfo } = useGetCandidateById(
    sessionStorage.getItem('candidateId'),
  )
  useEffect(() => {
    if (token && token.length > 0) {
      getRefereeDetails()
    }
  }, [agencyData, candidateProfile, token])
  useEffect(() => {
    dispatch(setRefereeDetails(refereeDetails?.referee))
  }, [refereeDetails])
  useEffect(() => {
    dispatch(
      setIsStaffRequest(
        searchParams.get('IsStaffRequest') === 'True' ||
          searchParams.get('IsStaffRequest') === 'true'
          ? true
          : false,
      ),
    )
    dispatch(
      setIsCareerInterest(
        searchParams.get('IsCareerInterest') === 'True' ||
          searchParams.get('IsCareerInterest') === 'true'
          ? true
          : false,
      ),
    )
  }, [searchParams])
  const CandidateDetailsClasses = CN(
    `candidate-details mt-5 md:mt-0`,
    className,
  )
  const CandidateDetailsRightSideClasses =
    'flex flex-row text-right md:text-left text-small font-SemiBold'

  const CandidateDetailsLeftSideClasses =
    'flex items-center text-extra-small font-Medium text-Gray-500'

  const EditButtonClasses = 'font-semi text-base focus:outline-none'

  const { name, avatarUrl } = candidateProfile || {}

  const { referenceType } = refereeDetails?.referee || {}

  const { name: agencyName } = agencyData || {}
  const { mutateAsync: declineRefCheck, isLoading: isLoadingDeclineRefCheck } =
    useDeclineRefCheck(candidateId, refereeId)
  const [isOpenDeclineCheck, setIsOpenDeclineCheck] = useState(false)

  const [editButtonStates, setEditButtonStates] = useState({
    startedOn: false,
    endedOn: false,
    isCurrentlyWorking: true,
    relationShip: false,
  })

  // Toggle the editing state for a button
  const toggleEditing = (button) => {
    setEditButtonStates((prevStates) => ({
      ...prevStates,
      [button]: !prevStates[button],
    }))
  }

  return (
    <div className={CandidateDetailsClasses} {...restProps}>
      {/* header section */}
      <TitleHeader
        title='Reference Check'
        subTitle={`${agencyName || ''} is requesting a reference check for:`}
      />

      {/* stepper section */}
      {referenceType?.type == ReferenceCheckType?.Employment && (
        <div className='flex p-5 mt-5 bg-white w-full justify-center items-center'>
          <LinearStepper steps={steps} currentStep={currentStep} />
        </div>
      )}

      {/* candidate detail and disclaimer section */}
      <div className='relative flex flex-col w-full h-auto p-4 mt-5 bg-white rounded-lg main-container'>
        {loadingRefereeDetailsById ||
          isLoadingCandidateInfo ||
          (isUpdatingIncorrectInformation && <PageLoader size='xxs' />)}

        {!isLoadingCandidateInfo && refereeDetails?.referee && (
          <Formik
            validationSchema={incorrectInformationSchema}
            initialValues={{
              jobTitle: refereeDetails?.referee.jobTitle || '',
              employmentStartDate: refereeDetails?.referee?.startDate
                ? new Date(refereeDetails?.referee?.startDate).toISOString()
                : new Date().toISOString(),
              employmentEndDate: refereeDetails?.referee?.endDate
                ? new Date(refereeDetails?.referee?.endDate).toISOString()
                : new Date().toISOString(),
              relationShip: refereeDetails?.referee?.candidateRelation || '',
              isCurrentlyWorking:
                refereeDetails?.referee?.isCurrentlyWorking || false,
            }}
            onSubmit={(values) => {
              correctIncorrectInformation({
                jobTitle: values.jobTitle,
                candidateRelation: values.relationShip,
                startDate: moment(values.employmentStartDate).format(
                  'YYYY-MM-DD',
                ),
                endDate: moment(values.employmentEndDate).format('YYYY-MM-DD'),
                isCurrentlyWorking: values.isCurrentlyWorking,
              }).then(() => {
                navigate(
                  `/candidate/${location.pathname.split('/')[2]}/referee/${
                    location.pathname.split('/')[4]
                  }/questionnaire`,
                )
              })
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isValid,
              /* and other goodies */
            }) => (
              <>
                <div className='flex flex-col items-center w-full candidate-detail-section md:flex-row md:items-start'>
                  {avatarUrl ? (
                    <div className='flex items-center justify-center object-fill overflow-hidden border rounded-full profile-image border-Gray-200 w-fit'>
                      <img
                        src={avatarUrl}
                        alt=''
                        className='flex w-[112px] md:w-[132px] h-auto object-contain rounded-full'
                      />
                    </div>
                  ) : (
                    <img
                      src={profileLogo}
                      alt=''
                      className='w-[112px] md:w-[132px] h-[112px] md:h-[132px] bg-Gray-200 rounded-full p-3'
                    />
                  )}

                  <div className='grid w-full grid-cols-2 my-4 ml-0 profile-info md:ml-6 md:gap-x-2 md:mt-0 gap-y-2 md:w-auto'>
                    {referenceType?.type != ReferenceCheckType?.Employment && (
                      <>
                        <div
                          className={CN(
                            CandidateDetailsLeftSideClasses,
                            'md:mt-5',
                          )}>
                          Name
                        </div>
                        <br className='hidden md:block' />
                        <div
                          className={CN(
                            CandidateDetailsRightSideClasses,
                            'md:-mt-1',
                          )}>
                          {name}
                        </div>
                        <br className='hidden md:block' />
                        <div className={CandidateDetailsLeftSideClasses}>
                          Relationship to you
                        </div>
                        <br className='hidden md:block' />
                        <div
                          className={CN(
                            CandidateDetailsRightSideClasses,
                            'md:-mt-1',
                          )}>
                          {capitalizeFirstLetter(values.relationShip || '')}
                        </div>
                        <br />
                      </>
                    )}
                    {referenceType?.type == ReferenceCheckType?.Employment && (
                      <>
                        <div className={CandidateDetailsLeftSideClasses}>
                          Name
                        </div>
                        <div
                          className={CN(CandidateDetailsRightSideClasses, {
                            'my-1': values.isCurrentlyWorking,
                          })}>
                          {name}
                        </div>

                        <div className={CandidateDetailsLeftSideClasses}>
                          Job title
                        </div>
                        <div
                          className={CN(CandidateDetailsRightSideClasses, {
                            'my-2': values.isCurrentlyWorking,
                          })}>
                          {candidateProfile.jobTitle
                            ? capitalizeFirstLetter(
                                candidateProfile.jobTitle || '',
                              )
                            : 'Not Available'}
                        </div>

                        <div className={CandidateDetailsLeftSideClasses}>
                          Employment started on
                        </div>
                        <div className={CandidateDetailsRightSideClasses}>
                          <CalendarInput
                            value={values.employmentStartDate}
                            formatDate={(date) => format(date, 'dd/MM/yyyy')}
                            parseDate={(str) =>
                              parse(str, 'dd/MM/yyyy', new Date())
                            }
                            rightElement={<i className={''} />}
                            className='mr-3 w-full'
                            onChange={(date) => {
                              setFieldValue('employmentStartDate', date)
                            }}
                            isErrored={
                              errors.employmentStartDate ? true : false
                            }
                            disableFutureDates={true}
                            helperText={
                              errors.employmentStartDate
                                ? errors.employmentStartDate
                                : ''
                            }
                            disabled={!editButtonStates.startedOn}
                          />
                          <button
                            onClick={() => toggleEditing('startedOn')}
                            className={CN(EditButtonClasses, {
                              '!text-[#105DA4]': editButtonStates.startedOn,
                              '!text-Gray-500': !editButtonStates.startedOn,
                            })}>
                            <i className='ri-pencil-line' />
                          </button>
                        </div>

                        <div className={CandidateDetailsLeftSideClasses}>
                          Employment ended on
                        </div>
                        {!values.isCurrentlyWorking ? (
                          <div className={CandidateDetailsRightSideClasses}>
                            <CalendarInput
                              value={values.employmentEndDate}
                              formatDate={(date) => format(date, 'dd/MM/yyyy')}
                              parseDate={(str) =>
                                parse(str, 'dd/MM/yyyy', new Date())
                              }
                              rightElement={<i className={''} />}
                              className='mr-3 w-full'
                              onChange={(date) => {
                                setFieldValue('employmentEndDate', date)
                              }}
                              isErrored={
                                errors.employmentEndDate ? true : false
                              }
                              helperText={
                                errors.employmentEndDate
                                  ? errors.employmentEndDate
                                  : ''
                              }
                              disabled={!editButtonStates.endedOn}
                            />
                            <button
                              onClick={() => toggleEditing('endedOn')}
                              className={CN(EditButtonClasses, {
                                '!text-[#105DA4]': editButtonStates.endedOn,
                                '!text-Gray-500': !editButtonStates.endedOn,
                              })}>
                              <i className='ri-pencil-line' />
                            </button>
                          </div>
                        ) : (
                          <>
                            {editButtonStates.isCurrentlyWorking ? (
                              <div
                                className={CN(
                                  CandidateDetailsRightSideClasses,
                                  {
                                    'my-1': values.isCurrentlyWorking,
                                  },
                                )}>
                                Currently working
                                <button
                                  onClick={() =>
                                    toggleEditing('isCurrentlyWorking')
                                  }
                                  className={CN(EditButtonClasses, {
                                    '!text-[#105DA4] pl-3':
                                      !editButtonStates.isCurrentlyWorking,
                                    '!text-Gray-500 pl-3':
                                      editButtonStates.isCurrentlyWorking,
                                  })}>
                                  <i className='ri-pencil-line' />
                                </button>
                              </div>
                            ) : (
                              <div className={CandidateDetailsRightSideClasses}>
                                <CalendarInput
                                  value={values.employmentEndDate}
                                  formatDate={(date) =>
                                    format(date, 'dd/MM/yyyy')
                                  }
                                  parseDate={(str) =>
                                    parse(str, 'dd/MM/yyyy', new Date())
                                  }
                                  rightElement={<i className={''} />}
                                  className='mr-3 w-full'
                                  onChange={(date) => {
                                    setFieldValue('employmentEndDate', date),
                                      setFieldValue('isCurrentlyWorking', false)
                                  }}
                                  isErrored={
                                    errors.employmentEndDate ? true : false
                                  }
                                  helperText={
                                    errors.employmentEndDate
                                      ? errors.employmentEndDate
                                      : ''
                                  }
                                  disabled={editButtonStates.isCurrentlyWorking}
                                />
                                <button
                                  onClick={() =>
                                    toggleEditing('isCurrentlyWorking')
                                  }
                                  className={CN(EditButtonClasses, {
                                    '!text-[#105DA4]':
                                      !editButtonStates.isCurrentlyWorking,
                                    '!text-Gray-500':
                                      editButtonStates.isCurrentlyWorking,
                                  })}>
                                  <i className='ri-pencil-line' />
                                </button>
                              </div>
                            )}
                          </>
                        )}

                        <div className={CandidateDetailsLeftSideClasses}>
                          Relationship to you
                        </div>
                        <div className={CandidateDetailsRightSideClasses}>
                          <TextField
                            isRequired
                            className='mr-3 w-full'
                            isErrored={
                              errors.relationShip && touched.relationShip
                            }
                            onChange={handleChange('relationShip')}
                            onBlur={handleBlur('relationShip')}
                            value={values.relationShip}
                            placeholder=''
                            isDisabled={!editButtonStates.relationShip}
                          />
                          <button
                            onClick={() => toggleEditing('relationShip')}
                            className={CN(EditButtonClasses, {
                              '!text-[#105DA4]': editButtonStates.relationShip,
                              '!text-Gray-500': !editButtonStates.relationShip,
                            })}>
                            <i className='ri-pencil-line' />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* web view disclaimer */}
                <div className='hidden p-4 mt-8 rounded-lg md:flex md:flex-col bg-Cobalt-50 text-small'>
                  <span className='text-heading-5 font-Medium text-Gray-800'>
                    Reference Check Disclaimer
                  </span>

                  {referenceType?.type == ReferenceCheckType?.Employment && (
                    <span className='mt-3 font-Regular text-black'>
                      By completing this form, you are confirming that you have
                      worked with the applicant. Please note that the content of
                      this reference is strictly confidential and is solely
                      intended for the applicant's overall evaluation.
                    </span>
                  )}

                  {referenceType?.type == ReferenceCheckType?.Professional && (
                    <span className='mt-3 font-Regular text-black'>
                      By completing this form, you are confirming that you have
                      a professional relationship with the applicant. Please
                      note that the content of this reference is strictly
                      confidential and is solely intended for the applicant's
                      overall evaluation.
                    </span>
                  )}

                  {referenceType?.type == ReferenceCheckType?.FriendFamily && (
                    <span className='mt-3 font-Regular text-black'>
                      By completing this form, you are confirming that you
                      personally know the applicant. Please note that the
                      content of this reference is strictly confidential and is
                      solely intended for the applicant's overall evaluation.
                    </span>
                  )}
                </div>

                <div className='flex flex-col md:mt-8 md:flex-row gap-y-3 md:gap-x-5'>
                  {referenceType?.type == ReferenceCheckType?.Employment && (
                    <>
                      <Button
                        isBlock
                        theme='white'
                        textTheme='black'
                        className='order-2 md:order-1'
                        onClick={() => setIsOpenDeclineCheck(true)}>
                        Decline to Answer
                      </Button>

                      <Button
                        isBlock
                        isDisabled={!isValid}
                        className='order-1  md:order-2'
                        onClick={handleSubmit}>
                        Confirm Employment History and Get Started
                      </Button>
                    </>
                  )}

                  {referenceType?.type != ReferenceCheckType?.Employment && (
                    <>
                      <Button
                        isBlock
                        onClick={() => {
                          navigate(
                            `/candidate/${
                              location.pathname.split('/')[2]
                            }/referee/${
                              location.pathname.split('/')[4]
                            }/questionnaire`,
                          )
                        }}>
                        Looks Good! Get Started
                      </Button>

                      <Button
                        isBlock
                        theme='white'
                        textTheme='blue'
                        onClick={() => setIsOpenDeclineCheck(true)}>
                        Decline to Answer
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}
          </Formik>
        )}

        {/* mobile view disclaimer */}
        <div className='flex justify-center w-full mt-6 md:hidden'>
          <Button
            onClick={() => {
              setShowDisclaimerModal(true)
            }}
            textTheme='blue'
            theme='link'>
            View Reference Check Disclaimer
          </Button>
        </div>
        {/* mobile view disclaimer modal */}
        <Modal
          headerTitle='Reference Check Disclaimer'
          isActive={showDisclaimerModal}
          isFooterShow={false}
          onHeaderCloseButtonClick={() => {
            setShowDisclaimerModal(false)
          }}
          onOverlayClick={() => {
            setShowDisclaimerModal(false)
          }}>
          <div className='flex flex-col h-auto p-6'>
            {referenceType?.type == ReferenceCheckType?.Employment && (
              <span className='mt-3'>
                By completing this form, you are confirming that you have worked
                with the applicant. Please note that the content of this
                reference is strictly confidential and is solely intended for
                the applicant's overall evaluation.
              </span>
            )}
            {referenceType?.type == ReferenceCheckType?.Professional && (
              <span className='mt-3'>
                By completing this form, you are confirming that you have a
                professional relationship with the applicant. Please note that
                the content of this reference is strictly confidential and is
                solely intended for the applicant's overall evaluation.
              </span>
            )}
            {referenceType?.type == ReferenceCheckType?.FriendFamily && (
              <span className='mt-3'>
                By completing this form, you are confirming that you personally
                know the applicant. Please note that the content of this
                reference is strictly confidential and is solely intended for
                the applicant's overall evaluation.
              </span>
            )}
          </div>
        </Modal>
        {/* Decline Referee modal */}
        <Modal
          isActive={isOpenDeclineCheck}
          onClickPrimaryBtn={() => {
            declineRefCheck()
              .then(() => {
                navigate('thankyou')
              })
              .finally(() => {
                setIsOpenDeclineCheck(false)
              })
          }}
          className={'p-4'}
          onClickSecondaryBtn={() => {
            setIsOpenDeclineCheck(false)
          }}
          primaryButtonProps={{
            style: { width: '100%', marginRight: '12px' },
            isLoading: isLoadingDeclineRefCheck,
          }}
          secondaryButtonProps={{
            style: { width: '100%', color: '#1F2937' },
          }}
          onOverlayClick={() => {
            setIsOpenDeclineCheck(false)
          }}
          modalProps={{
            style: { width: '466px' },
          }}
          footerProps={{
            style: {
              justifyContent: 'space-between',
              paddingLeft: '23px',
              paddingRight: '23px',
            },
          }}
          isHeaderShow={false}
          primaryButtonTitle='Yes, I’m Sure'
          secondaryButtonTitle='Close'>
          <div className='flex flex-col items-center justify-center p-6'>
            <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
              <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
            </div>
            <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
              Are you sure you want to Decline?
            </span>
          </div>
        </Modal>
      </div>
    </div>
  )
}
CandidateDetails.defaultProps = {}
export default CandidateDetails
