import React, { FC, useEffect, useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import { ReferenceCheckType } from 'types/referenceCheckType'

import 'react-circular-progressbar/dist/styles.css'

export interface AddRefereesProgressCardProps {
  currentReferee: number
  totalReferees: number
  isCompleted: boolean
  [x: string]: any
}

export const AddRefereesProgressCard: FC<AddRefereesProgressCardProps> = ({
  className,
  currentReferee,
  totalReferees,
  isCompleted,
  ...restProps
}: AddRefereesProgressCardProps) => {
  const AddRefereesProgressCardClasses = CN(
    `add-referees-progress-card bg-[#cee5fa80] rounded-lg px-4 py-2 border border-[#71B0EB] mt-3 flex justify-between items-start`,
    className,
  )
  const [refereeTypes, setRefereeTypes] = useState<any>(null)

  const { candidateProfile } = useSelector((state: any) => state.candidate)

  useEffect(() => {
    if (candidateProfile?.referenceTypes) {
      const notSubmittedReferees = candidateProfile?.referenceTypes?.filter(
        (ref) => ref.isSubmitted === false,
      )
      setRefereeTypes(notSubmittedReferees)
    }
  }, [candidateProfile?.referenceTypes])

  return (
    <div className={AddRefereesProgressCardClasses} {...restProps}>
      <div className='flex flex-col'>
        {isCompleted ? (
          <span className='mb-1 text-Gray-800 font-SemiBold text-heading-5'>
            Step{' '}
            {currentReferee + 1 <= 9
              ? `0${currentReferee + 1}`
              : ` ${currentReferee + 1}`}{' '}
            (Done)
          </span>
        ) : (
          <span className='mb-1 text-Gray-800 font-SemiBold text-heading-5'>
            Step{' '}
            {currentReferee <= 9 ? `0${currentReferee}` : ` ${currentReferee}`}{' '}
            Custom Request
          </span>
        )}

        {!isCompleted && (
          <div className='flex items-center gap-1'>
            <span className='text-Gray-500 font-Medium text-small'>
              Next:
              {/* {currentReferee === totalReferees
                ? ' Completed'
                : ` Referee 0${currentReferee + 1}`} */}
            </span>
            {currentReferee !== totalReferees ? (
              <span className='font-SemiBold text-small text-Gray-500 bg-Cobalt-100 py-[2px] px-1 rounded'>
                Step{' '}
                {currentReferee + 1 <= 9
                  ? `0${currentReferee + 1}`
                  : ` ${currentReferee + 1}`}{' '}
                (Referee
                {currentReferee + 1 <= 9
                  ? ` 0${currentReferee + 1}`
                  : ` ${currentReferee + 1}`}
                )
              </span>
            ) : (
              <span className='font-SemiBold text-small text-Gray-500 bg-Cobalt-100 py-[2px] px-1'>
                Step{' '}
                {currentReferee + 1 <= 9
                  ? `0${currentReferee + 1}`
                  : ` ${currentReferee + 1}`}{' '}
                (Done)
              </span>
            )}
          </div>
        )}

        {!isCompleted && (
          <table className='w-full mt-3'>
            <tbody>
              <tr className='flex w-full pb-3'>
                <td className='w-[150px] pt-1 text-Gray-600 font-Medium text-extra-small'>
                  Referee Type:
                </td>

                <td className='text-justify text-Gray-800 font-SemiBold text-small'>
                  {(refereeTypes &&
                    (refereeTypes[currentReferee - 1]?.type ===
                    ReferenceCheckType?.Employment
                      ? `Employment`
                      : refereeTypes[currentReferee - 1]?.type ===
                        ReferenceCheckType?.Professional
                      ? `Professional`
                      : `Friends & Family`)) ??
                    'NA'}
                </td>
              </tr>

              {refereeTypes &&
                refereeTypes[currentReferee - 1]?.type ===
                  ReferenceCheckType?.Employment && (
                  <tr className='flex w-full pb-3'>
                    <td className='pt-1 w-[150px] text-Gray-600 font-Medium text-extra-small'>
                      From Specific Employer:
                    </td>

                    <td className='text-justify text-Gray-800 font-SemiBold text-small'>
                      {refereeTypes &&
                      refereeTypes[currentReferee - 1]?.specificEmployer
                        ? refereeTypes &&
                          refereeTypes[currentReferee - 1]?.specificEmployer
                        : 'NA'}
                    </td>
                  </tr>
                )}

              <tr className='flex w-full pb-3'>
                <td className='pt-1 w-[150px] text-Gray-600 font-Medium text-extra-small'>
                  Specific Referee Note:
                </td>

                <td
                  className={CN(
                    'text-justify text-Gray-800 font-SemiBold text-small max-w-[60%] lg:max-w-[80%]',
                  )}>
                  {refereeTypes && refereeTypes[currentReferee - 1]?.note
                    ? refereeTypes && refereeTypes[currentReferee - 1]?.note
                    : 'NA'}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <div>
        <CircularProgressbar
          strokeWidth={12}
          className='w-[70px] h-[70px]'
          value={
            ((isCompleted ? currentReferee + 1 : currentReferee) /
              (totalReferees + 1 || 4)) *
            100
          }
          text={`${isCompleted ? currentReferee + 1 : currentReferee}/${
            totalReferees + 1
          }`}
          styles={{
            // Customize the path, i.e. the "completed progress"
            path: {
              stroke: `#1E75C6`,
              strokeLinecap: 'butt',
              transition: 'stroke-dashoffset 0.5s ease 0s',
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
              stroke: '#F3F4F6',
              strokeLinecap: 'butt',
              transformOrigin: 'center center',
            },
            // Customize the text
            text: {
              fill: '#1F2937',
              lineHeight: '20px',
              fontSize: '16px',
              fontWeight: '600',
              letterSpacing: '4px',
            },
            // Customize background - only used when the `background` prop is true
            background: {
              fill: '#3e98c7',
            },
          }}
        />
      </div>
    </div>
  )
}

AddRefereesProgressCard.defaultProps = {}

export default AddRefereesProgressCard
